import React from 'react'
import SentenceListItem from './SentenceListItem'

export default function SentenceList({ sentence_list, handleDefaultSentence, handleDeleteSentence}){

    return (
        <div>            
            <div className='ui grid'>
                <div className='ui sixteen wide column'>
                    <div className="ui middle aligned divided list">
                        {
                            sentence_list && sentence_list.map(sentence => (
                                <SentenceListItem 
                                    handleDefaultSentence={handleDefaultSentence} 
                                    handleDeleteSentence={handleDeleteSentence}
                                    key={sentence.id} 
                                    sentence={sentence} />
                            ))
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}