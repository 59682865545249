import React, { useState, useContext } from 'react'
import axios from 'axios'
import { produce } from 'immer'

export default function AddEntitie({ handleSetOnAdd, clause, handleEditClause }) {

    const [form, setForm] = useState({})

    function handleChange(e) {
        const value = e.target.value;
        setForm({
            ...form,
            [e.target.name]: value
        });
    }

    const handleCancel = () => {
        handleSetOnAdd()
    }

    const handleSave = () => {
        var data = {
            clause_id: clause.id,
            ...form
        }

        axios.post(`${window.BASE_URL}/entities/add`, data)
            .then(function (response) {
                const updated_clause = produce(clause, draftClause => {
                    let new_entities = [...draftClause.entities]
                    let new_ent = {
                        id: response.data.entity.id,
                        title: form.title,
                        entity_type: form.entity_type,
                        clause_id: clause.id
                    }

                    new_entities.push(new_ent)

                    draftClause.entities = new_entities
                })

                handleEditClause(updated_clause)

                handleSetOnAdd()
                
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <>
            <form className='ui form' style={{marginTop: '20px'}}>

                <div className='ui header'>Add Entity</div>


                <div className=" field">
                    <label>Title</label>
                    <input onChange={handleChange} value={form.title} type="text" name="title" placeholder="Title" />
                </div>
                <div className=" field">
                    <label>Entity Type</label>
                    <input onChange={handleChange} value={form.entity_type} type="text" name="entity_type" placeholder="Entity Type" />
                </div>
            </form>

            <div className='ui grid'>
                <div className='ui sixteen wide column'>
                    <div className='ui basic segment' >
                        <button onClick={handleSave} className='ui basic positive button right floated'>Add</button>
                        <button onClick={handleCancel} className='ui basic negative button right floated'>Cancel</button>
                    </div>
                </div>
            </div>

        </>
    )
}