import React from 'react'
import EntitiesContainer from './EntitiesContainer'
import CharacteristicContainer from '../Characteristic/CharacteristicContainer'

export default function EntitiesCharacteristicContainer({ clause, handleEditClause }) {

    return (
        <>  
            <div className='ui segment'>
                <CharacteristicContainer handleEditClause={handleEditClause} clause={clause} />
            </div>
            
            <div className='ui segment'>
                <EntitiesContainer handleEditClause={handleEditClause} clause={clause} />
            </div>
        </>
    )
}