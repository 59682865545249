import React from 'react'

export default function ClauseListItem({ clause, onClickHandle }){

    return (
        <div className="item">
            {/* <i class="large github middle aligned icon"></i> */}
            <div className="content">
                <a className="header" onClick={onClickHandle}>{clause.caption}</a>
                <div className="description">{clause.sentence}</div>
            </div>
        </div>
    )
}

