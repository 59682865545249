import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import SideBarAgreementList from './SideBarAgreementList'

import { ShouldUpdateSidebarAgreementListContext } from '../../App'

function Sidebar() {

    const [should_update_agreement_list, handleSetShouldUpdateAgreementList] = useContext(ShouldUpdateSidebarAgreementListContext)

    const [agreement_list, SetAgreementList] = useState([])

    const { user } = useAuth0()

    useEffect(() => {
        axios.get(`${window.BASE_URL}/agreement/get_agreement_list`)
            .then(function (response) {
                SetAgreementList(response.data.agreement_list)
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    }, [should_update_agreement_list])

    return (
        <div id="sidebar" className="ui left fixed inverted vertical menu" style={{ overflowY: 'scroll', marginTop: '40px', paddingTop: '35px' }}>
            <Link className="item" to={'/'}>
                <i class="edit outline icon"></i>Analysis - Readability
            </Link>

            <Link className="item" to={'/cost-estimate'}>
                <i class="edit outline icon"></i>Analysis - Cost Estimate
            </Link>
            
            { user ? <SideBarAgreementList agreement_list={agreement_list} /> : <></>}
            
        </div>
    )
}

export default Sidebar