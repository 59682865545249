import React, { useState } from 'react'
import { Item } from 'semantic-ui-react'
import Toggle from 'react-toggle'
import { Accordion, Icon } from 'semantic-ui-react'

export default function TokensHighlighter({
    entities_active,
    toggleEntities,
    defined_terms_active,
    toggleDefinedTerms,
    obligations_active,
    toggleObligations,
    legalease_active,
    toggleLegalease,
    spaced_line_breaks_active,
    toggleSpacedLineBreaks
}) {

    const [active, setActive] = useState(false)

    const handleActive = () => {
        setActive(!active)
    }

    return (
        <div className='ui segment basic'>
            <Accordion fluid styled>
                <Accordion.Title active={active} index={0} onClick={handleActive}>
                    <Icon name='dropdown' />Readability Options</Accordion.Title>
                <Accordion.Content active={active}>
                    <Item.Group divided>
                        <Item>
                            <label style={{ display: 'flex', alignItems: 'center' }}>
                                <Toggle
                                    checked={spaced_line_breaks_active}
                                    defaultChecked={spaced_line_breaks_active}
                                    icons={false}
                                    onChange={toggleSpacedLineBreaks}
                                    className='custom-classname-grey'
                                />
                                <span>Break by Sentence</span>
                            </label>
                        </Item>
                        <Item>
                            <label style={{ display: 'flex', alignItems: 'center' }}>
                                <Toggle
                                    checked={entities_active}
                                    defaultChecked={entities_active}
                                    icons={false}
                                    onChange={toggleEntities}
                                    className='custom-classname-green'
                                />
                                <span>Named Entities</span>
                            </label>
                        </Item>
                        <Item>
                            <label style={{ display: 'flex', alignItems: 'center' }}>
                                <Toggle
                                    checked={defined_terms_active}
                                    defaultChecked={defined_terms_active}
                                    icons={false}
                                    onChange={toggleDefinedTerms}
                                    className='custom-classname-blue'
                                />
                                <span>Defined Terms</span>
                            </label>
                        </Item>
                        <Item>
                            <label style={{ display: 'flex', alignItems: 'center' }}>
                                <Toggle
                                    checked={obligations_active}
                                    defaultChecked={obligations_active}
                                    icons={false}
                                    onChange={toggleObligations}
                                    className='custom-classname-red'
                                />
                                <span>Obligations</span>
                            </label>
                        </Item>
                        <Item>
                            <label style={{ display: 'flex', alignItems: 'center' }}>
                                <Toggle
                                    checked={legalease_active}
                                    defaultChecked={legalease_active}
                                    icons={false}
                                    onChange={toggleLegalease}
                                    className='custom-classname-orange'
                                />
                                <span>Legalease</span>
                            </label>
                        </Item>
                    </Item.Group>
                </Accordion.Content>
            </Accordion>
        </div>
    )
}