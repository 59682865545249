import React from 'react'
import axios from 'axios'

export default function DeleteFrameworkClause({ framework, clause, handleDeleteClause }) {

    const handleDelete = () => {

        var data = {
            frameworkId: framework.id,
            clauseId: clause.id
        }

        axios.post(`${window.BASE_URL}/framework/deleteClause`, data)
            .then(function (response) {
                handleDeleteClause(framework, clause)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div className="ui two wide column">
            <div onClick={handleDelete} className="ui negative labeled icon button">
                Delete<i className="trash icon"></i>
            </div>
        </div>
    )
}