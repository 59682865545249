import React, { useState } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { Dimmer, Loader } from 'semantic-ui-react'
import { makeErrorToast, makeSuccessToast } from '../makeToast'
import "react-toggle/style.css"
import axios from 'axios'
import ReadabilityGaugeChart from './ReadabilityGaugeChart'
import TokensHighlighter from './TokensHighlighter'
import produce from 'immer'
import TokenDisplayer from './TokenDisplayer'
import Calculations from './Calculations'
import Explanation from './Explanation'
import AnalisysLogos from './AnalysisLogos'

export default function AnalysisContainer({ children }) {

    const [editor_html_content, setEditorHTMLContent] = useState('')
    const [editor_text_content, setEditorTextContent] = useState('')
    const [dimmer_active, setDimmerActive] = useState(false)
    const [review_data, setReviewData] = useState()
    const [raw_readability, setRawReadability] = useState(0)
    const [highest_raw_readability, setHighestRawReadability] = useState(0)
    const [highest_raw_readability_text, setHighestRawReadabilityText] = useState(0)

    const [entities_active, setEntitiesActive] = useState(false)
    const [defined_terms_active, setDefinedTermsActive] = useState(false)
    const [obligations_active, setObligationsActive] = useState(false)
    const [legalease_active, setLegaleaseActive] = useState(false)
    const [spaced_line_breaks_active, setSpacedLineBreaksActive] = useState(false)

    const [edit_mode, setEditMode] = useState(true)

    //explanation data
    const [explanation_form, setExplanationForm] = useState({
        base_reading_grade: 8,
        base_reading_words_per_minute: 500,
        base_payscale: 80,

        reading_speed_decrease_per_grade_level: 0.1,
        reviews_per_grade: 0.1,
        reviewver_administrator_increase_per_grade: 0.1,
        payscale_increase_per_grade: 0.1,

        agreement_require_active_management: false,
        conformity_to_company_standards: 80
    })

    const handleExplanationForm = (e) => {

        let value = ''

        if(e.target.type === 'checkbox'){
            value = e.target.checked;
        }else{
            value = e.target.value;
        }

        setExplanationForm({
            ...explanation_form,
            [e.target.name]: value
        });
    }

    const handleEditorOnChange = (content, editor) => {
        let text = editor.getContent({ format: 'text' })
        let html = editor.getContent({ format: 'html' })

        setEditorHTMLContent(html);
        setEditorTextContent(text)
    }

    const handleRunAnalyze = () => {

        setDimmerActive(true)

        var data = {
            clauses_to_review: [],
            content: editor_text_content,
            rule_matcher: [],
            explanation_form: explanation_form
        }

        axios.post(`${window.BASE_URL}/framework/analyzeRuleMatcher`, data)
            .then(function (response) {
                makeSuccessToast('Analysis done.')
                setReviewData(response.data);

                setRawReadability(response.data.readability_avg)
                setHighestRawReadability(response.data.readability_highest_sentence_score)
                setHighestRawReadabilityText(response.data.readability_highest_sentence_text)
                setEntitiesActive(false)
                setDefinedTermsActive(false)
                setObligationsActive(false)
                setLegaleaseActive(false)
                setSpacedLineBreaksActive(false)
                setEditMode(false)
                setDimmerActive(false)

                window.scrollTo(0, 0)
            })
            .catch(function (error) {
                makeErrorToast('Something went wrong when analysing the text.')
                console.log(error);
                setDimmerActive(false)
            });
    }

    const handleReCalculate = () => {
        var data = {
            words: review_data.word_count,
            grade_level: review_data.readability_avg,
            explanation_form: explanation_form
        }

        axios.post(`${window.BASE_URL}/framework/calculate_cost`, data)
            .then(function (response) {
                const nextState = produce(review_data, draftState => {
                    draftState.calculated_cost = response.data
                })

                setReviewData(nextState)

                makeSuccessToast('Re-calculate done.')
            })
            .catch(function (error) {
                makeErrorToast('Something went wrong when analysing the text.')
                console.log(error);
                setDimmerActive(false)
            });
    }

    const toggleEntities = () => {

        const nextState = produce(review_data, draftState => {
            draftState && draftState.content_tokenized.map(t => {
                if (t.type === 'ENTITY') {
                    t.active = !entities_active
                }
            })
        })

        setReviewData(nextState)

        setEntitiesActive(!entities_active)
    }

    const toggleDefinedTerms = () => {

        const nextState = produce(review_data, draftState => {
            draftState && draftState.content_tokenized.map(t => {
                if (t.type === 'DEFINED_TERM') {
                    t.active = !defined_terms_active
                }
            })
        })

        setReviewData(nextState)

        setDefinedTermsActive(!defined_terms_active)
    }

    const toggleObligations = () => {

        const nextState = produce(review_data, draftState => {
            draftState && draftState.content_tokenized.map(t => {
                if (t.type === 'OBLIGATIONS' || t.type === 'NEGATIVE') {
                    t.active = !obligations_active
                }
            })
        })

        setReviewData(nextState)

        setObligationsActive(!obligations_active)
    }

    const toggleLegalease = () => {

        const nextState = produce(review_data, draftState => {
            draftState && draftState.content_tokenized.map(t => {
                if (t.type === 'LEGALEASE') {
                    t.active = !legalease_active
                }
            })
        })

        setReviewData(nextState)

        setLegaleaseActive(!legalease_active)
    }

    const toggleSpacedLineBreaks = () => {

        const nextState = produce(review_data, draftState => {
            draftState && draftState.content_tokenized.map(t => {

                if (t.break_options.can_break){
                    t.break_options.active = !spaced_line_breaks_active
                }
            })
        })

        setReviewData(nextState)

        setSpacedLineBreaksActive(!spaced_line_breaks_active)
    }

    const handleEditMode = () => {
        setEditMode(!edit_mode)
    }

    return (
        <div style={{ marginTop: '120px', marginLeft: '20rem', marginRight: '5rem', minWidth: '800px' }}>

            {React.Children.toArray(children).map(child => {
                if (child.type === AnalisysLogos) {
                    return React.cloneElement(<AnalisysLogos />, { explanation: review_data && review_data.calculated_cost.explanation })
                }
            })
            }

            <Dimmer.Dimmable dimmed={dimmer_active} >
                <Dimmer active={dimmer_active} inverted>
                    <Loader>Loading</Loader>
                </Dimmer>
                <div className='ui grid'>
                    <div className='ui twelve wide column'>
                        
                        {
                            edit_mode
                                ?
                                <>
                                    <Editor
                                        apiKey='g7mom99j970quzbu2wfz7e60ulu0a9hfl40hwsnbwb0samwy'
                                        initialValue={editor_html_content}
                                        init={{
                                            height: 400,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar:
                                                'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                                        }}
                                        onEditorChange={handleEditorOnChange}
                                    />

                                    <div className="ui clearing segment basic">
                                        <div className="ui segment basic">
                                            {
                                                edit_mode
                                                    ?
                                                    <>
                                                        <button onClick={handleRunAnalyze} className="ui basic green button right floated">Run Complete Analysis</button>

                                                        {
                                                            review_data
                                                                ?
                                                                <button onClick={handleEditMode} className='ui basic button right floated'>Check Last Result</button>
                                                                :
                                                                <></>
                                                        }
                                                    </>
                                                    :
                                                    <button onClick={handleEditMode} className='ui basic button right floated'>Go Back</button>
                                            }
                                        </div>
                                    </div>

                                </>
                                :
                                <>
                                    <TokenDisplayer tokens={review_data && review_data.content_tokenized} />
                                    <div className="ui clearing segment basic">
                                        <div className="ui segment basic">
                                            <button onClick={handleEditMode} className='ui basic button right floated'>Go Back</button>
                                        </div>
                                    </div>
                                </>
                        }
                        
                    </div>
                    
                    <div className='ui four wide column'>
                        <ReadabilityGaugeChart 
                            raw_readability={raw_readability} 
                            highest_raw_readability={highest_raw_readability}
                            highest_raw_readability_text={highest_raw_readability_text}
                            sentences_count={review_data ? review_data.sentence_count : 0}
                            word_count={review_data ? review_data.word_count : 0}
                            calculated_cost={review_data ? review_data.calculated_cost : 0}
                        />
                        
                        <TokensHighlighter 
                            entities_active={entities_active}
                            toggleEntities={toggleEntities}

                            defined_terms_active={defined_terms_active}
                            toggleDefinedTerms={toggleDefinedTerms}

                            obligations_active={obligations_active}
                            toggleObligations={toggleObligations}

                            legalease_active={legalease_active}
                            toggleLegalease={toggleLegalease}

                            spaced_line_breaks_active={spaced_line_breaks_active}
                            toggleSpacedLineBreaks={toggleSpacedLineBreaks}
                        />

                        {React.Children.toArray(children).map(child => {
                            if (child.type === Calculations) {
                                return React.cloneElement(<Calculations />, { calculated_cost: review_data && review_data.calculated_cost })
                            }
                        })
                        }

                        {React.Children.toArray(children).map(child => {
                            if (child.type === Explanation) {
                                return React.cloneElement(<Explanation />, { 
                                    explanation_form: explanation_form,
                                    editExplanationForm: handleExplanationForm,
                                    handleReCalculate: handleReCalculate,
                                    display_recalculate: review_data ? true : false
                                })
                            }
                        })
                        }

                    </div>
                </div>
            </Dimmer.Dimmable>

        </div>
    )
}