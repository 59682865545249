import React, { useState, useEffect, useContext } from 'react';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import EditClausePopup from '../../Clause/EditClausePopup'
import EditSeparator from '../../Clause/EditSeparator'
import axios from 'axios'
import { AgreementContext } from '../../Agreement/Agreement'
import FrameworkClauseItem from '../../Clause/FrameworkClauseItem'
import SentenceContainer from '../../Sentence/SentenceContainer'
import DeleteFrameworkClause from '../../Clause/DeleteFrameworkClause'

const EditClauseWrapper = sortableElement(({ 
    clause, 
    handleEditClause, 
    handleDeleteClause, 
    framework,
    handleSetAgreement }) => <EditClausePopup 
                                DeleteClauseButton={
                                        <DeleteFrameworkClause 
                                            clause={clause} 
                                            framework={framework} 
                                            handleDeleteClause={handleDeleteClause} />}
                                framework={framework} 
                                SentenceContainer={<SentenceContainer 
                                                    clause={clause} 
                                                    handleSetAgreement={handleSetAgreement} />} 
                                clause={clause} 
                                handleEditClause={handleEditClause}>
                                    <FrameworkClauseItem current_clause={clause} />
                            </EditClausePopup>);

const EditSeparatorWrapper = sortableElement(({ separator }) => <EditSeparator separator={separator} />);

const SortableContainer = sortableContainer(({ children }) => {
    return <div className="ui divided link items sortable">{children}</div>;
});

export default function FrameworkBoxClauseList({ clauses, separators, framework }) {

    const [agreement, handleSetAgreement] = useContext(AgreementContext)

    const [current_clauses_separators, setClausesSeparators] = useState()

    useEffect(() => {
        const clauses_separators = clauses.concat(separators)
        const clauses_separators_sorted = clauses_separators.sort((a, b) => (a.sort_order > b.sort_order) ? 1 : ((b.sort_order > a.sort_order) ? -1 : 0));
        setClausesSeparators(clauses_separators_sorted)
    }, [clauses, separators])

    const onSortEnd = ({ oldIndex, newIndex }) => {

        let sorted = arrayMove(current_clauses_separators, oldIndex, newIndex)

        setClausesSeparators(sorted);

        let sorted_clauses = []
        let sorted_separators = []
        sorted.map((item, index) => {

            if (item.is_separator) {
                sorted_separators.push({
                    separator_id: item.id,
                    sort_order: index
                })

                handleSetAgreement(agreement => {
                    agreement.frameworks.map(f => {
                        f.separators.map(s => {
                            if (s.id === item.id) {
                                s.sort_order = index
                            }
                        })
                    })
                })
            } else {
                sorted_clauses.push({
                    clause_id: item.id,
                    sort_order: index
                })

                handleSetAgreement(agreement => {
                    agreement.frameworks.map(f => {
                        f.clauses.map(c => {
                            if (c.id === item.id) {
                                c.sort_order = index
                            }
                        })
                    })
                })
            }

            handleSetAgreement(agreement => {
                agreement.frameworks.map(f => {
                    f.clauses.sort((a, b) => (a.sort_order > b.sort_order) ? 1 : ((b.sort_order > a.sort_order) ? -1 : 0))
                })
            })
        })

        let data = JSON.stringify({
            sorted_clauses: sorted_clauses,
            sorted_separators: sorted_separators
        })

        axios.post(`${window.BASE_URL}/clause/reorderClauses`, data)
            .then(function (response) {

            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const handleEditClause = (clause) => {
        handleSetAgreement(agreement => {
            agreement.frameworks.map(function (f, i) {
                if (f.id === clause.framework_id) {
                    f.clauses.map(function (c, i) {
                        if (c.id === clause.id) {
                            c = Object.assign(c, { ...clause })
                        }
                    })
                }
            })
        })
    }

    const handleDeleteClause = (framework, clause) => {
        agreement.frameworks.map(function (f, i) {
            handleSetAgreement(agreement => {
                agreement.frameworks.map(function (f, i) {

                    if (f.id === framework.id){
                        const updated_clauses = f.clauses.filter(function (c, i) {
                            if (c.id !== clause.id ) {
                                return c
                            }
                        })

                        f.clauses = updated_clauses
                    }                    
                })
            })
        })
    }

    return (
        <SortableContainer helperClass="sortableHelper" onSortEnd={onSortEnd} useDragHandle>
            {current_clauses_separators && current_clauses_separators.map((item, index) => (
                item.is_separator
                    ?
                    <EditSeparatorWrapper key={item.id} index={index} separator={item} />
                    :
                    <EditClauseWrapper 
                        framework={framework} 
                        key={item.id} 
                        index={index} 
                        clause={item} 
                        handleEditClause={handleEditClause} 
                        handleDeleteClause={handleDeleteClause}
                        handleSetAgreement={handleSetAgreement} />
            ))}
        </SortableContainer>
    )
}