import React, {useState, useEffect} from 'react'
import axios from 'axios'

export default function DependencyChart({ content }) {
    const [dependecy_chart, setDependecyChart] = useState('')

    useEffect(()=>{

        if (content !== ''){
            var data = {
                content: content
            }

            axios.post(`${window.BASE_URL}/framework/get_dependecy_parser`, data)
                .then(function (response) {
                    setDependecyChart(response.data.dependency_parser);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    }, [content])

    return (
        <div className='ui basic segment' style={{ overflowX: 'scroll' }}>
            {
                <div dangerouslySetInnerHTML={{ __html: dependecy_chart }}></div>
            }
        </div>
    )
}