import React, {useState, useEffect, useContext} from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { makeSuccessToast } from '../../makeToast'
import { AgreementContext } from '../../Agreement/Agreement'

export default function ImportFrameworkModal(){

    const { id } = useParams()

    const [isOpen, setIsOpen] = useState(false)

    const handleClose = () => {
        setIsOpen(!isOpen)
    }

    const [agreement, handleSetAgreement] = useContext(AgreementContext)
    const [frameworks_to_import, setFrameworkToImport] = useState([])
    const [list_to_import, setListToImport] = useState([])

    useEffect(() => {

        if(isOpen){
            let data = {
                agreementId: id
            }

            axios.post(`${window.BASE_URL}/framework/getFrameworksToImport`, data)
                .then(function (response) {
                    setFrameworkToImport(response.data.frameworks)
                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(function () {
                    // always executed
                });
        }else{
            setFrameworkToImport([])
            setListToImport([])
        }

    }, [isOpen])

    const handleImport = () => {

        let data = {
            frameworksIds: JSON.stringify(list_to_import),
            agreementId: agreement.agreement_id
        }

        axios.post(`${window.BASE_URL}/framework/importFramework`, data)
            .then(function (response) {
                handleSetAgreement(agreement => response.data.agreement)
                makeSuccessToast('Agreement updated with the new frameworks.')
                setIsOpen(false)
            })
            .catch(function (error) {
                makeSuccessToast('Something went wrong with your request.')
            });

        setIsOpen(!isOpen)
    }

    const handleChecked = (e) => {

        let already_added = list_to_import.includes(e.target.value)

        if(already_added){
            let updated = list_to_import.filter(id => id !== e.target.value)
            setListToImport(updated)

        }else{
            setListToImport([...list_to_import, e.target.value])
        }

    }

    return(
        <Modal onClose={handleClose} open={isOpen} trigger={<Button onClick={e => handleClose(e.target.value)}>Clone Framework</Button>}>
            <Modal.Header>Select Frameworks to import</Modal.Header>
            <Modal.Content>
                <div className="ui middle aligned divided list">
                    {
                        frameworks_to_import && frameworks_to_import.map( f => 
                            <div key={f.id} className="item">
                                <div className="ui checkbox">
                                    <input type="checkbox" onChange={handleChecked} value={f.id} />
                                    <label>{f.title}</label>
                                </div>
                            </div>
                        )
                    }
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose} negative>
                    Cancel
                </Button>
                <Button
                    onClick={handleImport}
                    positive
                    labelPosition='right'
                    icon='checkmark'
                    content='Import'
                />
            </Modal.Actions>
        </Modal>
    )
}
