import React, { useContext } from 'react'
import axios from 'axios'
import { GetDependencyChartContext, CurrentSentenceEditModeContext, SentenceEditModeActiveContext } from './SentenceContainer'

export default function SentenceListItem({ sentence, handleDefaultSentence, handleDeleteSentence }) {

    const [dep_chart_text, handleSetDepChartText] = useContext(GetDependencyChartContext)
    const [current_sentence_edit, handleOnSentenceEditMode] = useContext(CurrentSentenceEditModeContext)
    const [on_sentence_edit_mode, handleOnSentenceEditActiveMode] = useContext(SentenceEditModeActiveContext)

    const handleChartText = () => {
        handleSetDepChartText(sentence.content)
    }

    const handleDelete = () => {

        var data = {
            id: sentence.id
        }

        axios.post(`${window.BASE_URL}/sentence/delete`, data)
            .then(function (response) {
                handleDeleteSentence(sentence)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleChecked = (e) => {
        var data = {
            id: sentence.id,
            clauseId: sentence.clause_id
        }

        axios.post(`${window.BASE_URL}/sentence/changeDefault`, data)
            .then(function (response) {
                handleDefaultSentence(sentence)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleParentOnSentenceEditActiveMode = () => {
        handleOnSentenceEditActiveMode()
        handleOnSentenceEditMode(sentence)
    }

    return (

        <div className="item">
            <div className="right floated content">
                <div className="ui mini basic button" onClick={handleChartText}><i style={{ margin: '0px' }} className="sitemap icon"></i></div>
                <div className="ui mini positive button" onClick={handleParentOnSentenceEditActiveMode}>Edit</div>
                <div className="ui mini negative button" onClick={handleDelete}>Delete</div>
            </div>
            <div className="ui radio checkbox">
                <input type="radio" name='sentence' onChange={handleChecked} defaultChecked={sentence.default} />
                <label>{sentence.title}</label>
            </div>
        </div >

    )
}