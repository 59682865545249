import React, { useState, useContext } from 'react'
import AddSentence from './AddSentence'
import SentenceList from './SentenceList'
import DependencyChart from './DependencyChart'
import EditSentence from './EditSentence'

export const GetDependencyChartContext = React.createContext()
export const CurrentSentenceEditModeContext = React.createContext()
export const SentenceEditModeActiveContext = React.createContext()

//if inside an agreement use handleSetAgreement to update the whole agreement state
//if not inside and agreement eg:listing all clauses, use the setClause
export default function SentenceContainer({ clause, handleSetAgreement, setClauses }) {

    const handleAddSentence = (clause, response) => {
        if (handleSetAgreement) {
            handleSetAgreement(agreement => {
                agreement.frameworks.map(f => {
                    f.clauses.map(c => {
                        if (c.id === clause.id) {
                            const new_sentence = {
                                id: response.data.sentence.pk,
                                title: response.data.sentence.fields.title,
                                content: response.data.sentence.fields.content,
                                clause_id: response.data.sentence.fields.clause,
                                default: response.data.sentence.fields.default
                            }

                            if (c.sentences && c.sentences.length > 0) {
                                c.sentences = [...c.sentences, new_sentence]
                            } else {
                                c.sentences = [new_sentence]
                            }
                        }
                    })
                })
            })
        }else{
            setClauses(clauses => {
                clauses.map(c => {
                    if (c.id === clause.id) {
                        const new_sentence = {
                            id: response.data.sentence.pk,
                            title: response.data.sentence.fields.title,
                            content: response.data.sentence.fields.content,
                            clause_id: response.data.sentence.fields.clause,
                            default: response.data.sentence.fields.default
                        }

                        if (c.sentences && c.sentences.length > 0) {
                            c.sentences = [...c.sentences, new_sentence]
                        } else {
                            c.sentences = [new_sentence]
                        }
                    }
                })
            })
        }
    }

    const handleDeleteSentence = (sentence) => {
        if (handleSetAgreement) {
            handleSetAgreement(agreement => {
                agreement.frameworks.map(f => {
                    f.clauses.map(c => {
                        if (c.id === sentence.clause_id) {
                            const new_sentences = c.sentences.filter(s => s.id !== sentence.id)
                            c.sentences = new_sentences
                        }
                    })
                })
            })
        }else{
            setClauses(clauses => {
                clauses.map(c => {
                    if (c.id === sentence.clause_id) {
                        const new_sentences = c.sentences.filter(s => s.id !== sentence.id)
                        c.sentences = new_sentences
                    }
                })
            })
        }
    }

    const handleEditSentence = (sentence, sentence_form) => {
        if (handleSetAgreement) {
            handleSetAgreement(agreement => {
                agreement.frameworks.map(f => {
                    f.clauses.map(c => {
                        c.sentences.map(s => {
                            if (s.id === sentence.id) {
                                s = Object.assign(s, { ...sentence_form })
                            }
                        })
                    })
                })
            })
        } else {
            setClauses(clauses => {
                clauses.map(c => {
                    c.sentences.map(s => {
                        if (s.id === sentence.id) {
                            s = Object.assign(s, { ...sentence_form })
                        }
                    })
                })
            })
        }
    }

    const handleDefaultSentence = (sentence) =>{
        if (handleSetAgreement) {
            handleSetAgreement(agreement => {
                agreement.frameworks.map(f => {
                    f.clauses.map(c => {
                        if (c.id === sentence.clause_id) {
                            c.sentences.map(s => {
                                if (s.id === sentence.id) {
                                    s.default = true
                                } else {
                                    s.default = false
                                }
                            })
                        }
                    })
                })
            })
        }else{
            setClauses(clauses => {
                clauses.map(c => {
                    c.sentences.map(s => {
                        if (c.id === sentence.clause_id) {
                            c.sentences.map(s => {
                                if (s.id === sentence.id) {
                                    s.default = true
                                } else {
                                    s.default = false
                                }
                            })
                        }
                    })
                })
            })
        }
    }

    const [dep_chart_text, setDepChartText] = useState('')
    const handleSetDepChartText = (content) => {
        setDepChartText(content)
    }

    const [on_sentence_edit_mode, setOnSentenceEditActiveMode] = useState(false)
    const handleOnSentenceEditActiveMode = () => {
        setOnSentenceEditActiveMode(!on_sentence_edit_mode)
    }

    const [current_sentence_edit, setCurrentSentenceEdit] = useState({})
    const handleCurrentSentenceEditMode = (sentence) => {
        setCurrentSentenceEdit(sentence)
    }

    return (
        <div>
            <SentenceEditModeActiveContext.Provider value={[on_sentence_edit_mode, handleOnSentenceEditActiveMode]}>
                <CurrentSentenceEditModeContext.Provider value={[current_sentence_edit, handleCurrentSentenceEditMode]}>
            {
                on_sentence_edit_mode
                    ?
                        <EditSentence handleEditSentence={handleEditSentence} sentence={current_sentence_edit} />
                    :
                    <><div className='ui header'>Sentences</div>
                        <GetDependencyChartContext.Provider value={[dep_chart_text, handleSetDepChartText]}>

                                    <SentenceList 
                                        handleDefaultSentence={handleDefaultSentence} 
                                        handleDeleteSentence={handleDeleteSentence}
                                        sentence_list={clause.sentences} />
                                    <AddSentence handleAddSentence={handleAddSentence} clause={clause} />
                                    <DependencyChart content={dep_chart_text} />

                        </GetDependencyChartContext.Provider>
                    </>
            }
                </CurrentSentenceEditModeContext.Provider>
            </SentenceEditModeActiveContext.Provider>

        </div>

    )
}