import React,{useContext} from 'react'
import { produce } from 'immer'
import axios from 'axios'

export default function EntitiesListItem({ clause, entity, handleSetOnEdit, handleEditClause}){

    const handleSetOnCurrentEdit = () => {
        handleSetOnEdit(entity)
    }

    const handleDelete = () => {

        var data = {
            id: entity.id
        }

        axios.post(`${window.BASE_URL}/entities/delete`, data)
            .then(function (response) {
                const updated_clause = produce(clause, draftClause => {
                    const new_entities = draftClause.entities.filter(e => e.id !== entity.id)
                    draftClause.entities = new_entities
                })

                handleEditClause(updated_clause)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return(
        <div className="item">
            <div className="right floated content">
                <div className="ui mini positive button" onClick={handleSetOnCurrentEdit}>Edit</div>
                <div className="ui mini negative button" onClick={handleDelete}>Delete</div>
            </div>
            <label>{entity.title}</label>
        </div >
    )
}