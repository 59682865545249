import React, {useState} from 'react'

export default function ObligationConditional({ condition }) {

    const [hide, setHide] = useState(true)

    const handleHide = () => {
        setHide(!hide)
    }

    return (
        <span onClick={handleHide}>{hide ? '[...] ' : condition}</span>
    )

}