import React from 'react'

import EditFramework from './EditFramework'
import AddClause from '../../Clause/AddClause'
import FrameworkBoxClauseList from './FrameworkBoxClauseList'
import ImportClause from '../../Clause/ImportClause'

function FrameworkBox({ framework }) {

    return (
        <div className="ui five wide column">
            <div className="ui fluid inverted card" >
                <div className="content">

                    <EditFramework framework={ framework }/>

                    <div className="ui blue header">{framework.title}</div>
                    <h4 className="ui sub header">{framework.caption}</h4>

                    <div className="ui divider"></div>

                    <FrameworkBoxClauseList 
                        framework={framework}
                        clauses={framework.clauses && framework.clauses }
                        separators={framework.separators && framework.separators}/>

                </div>

                <div className='ui two bottom attached buttons'>
                    <AddClause framework={framework} />
                    <ImportClause framework={framework}/>
                </div>
                
            </div>
        </div>

    )
}

export default FrameworkBox