import React from 'react'
import AddFramework from './AddFramework'
import ImportFramework from './ImportFramework'

function FrameworkMenu() {

    return (
        <div>
            <AddFramework />
            <ImportFramework />
        </div>
    )
}

export default FrameworkMenu