import React, {useState} from 'react'
import SideBarAgreementItem from './SideBarAgreementItem'
import { Accordion, Icon, Segment } from 'semantic-ui-react'

export default function SideBarAgreementList({ agreement_list }){

    const [activeIndex, setActiveIndex] = useState(0)
    const handleClick = (e, titleProps) => {
        const { index } = titleProps
        const newIndex = activeIndex === index ? -1 : index

        setActiveIndex(newIndex)
    }

    return(
        <div className=''>
            <Segment inverted>
                <Accordion inverted>
                    <Accordion.Title
                        active={activeIndex === 0}
                        index={0}
                        onClick={handleClick}><Icon name='dropdown' />Agreement
                    </Accordion.Title>
                    {
                        agreement_list && agreement_list.map(agreement =>
                            <SideBarAgreementItem key={agreement.pk} agreement={agreement} active={activeIndex === 0} />
                        )
                    }
                </Accordion>
            </Segment>
        </div>
    )
}