import React, {useState, useEffect} from 'react'
import ObligationConditional from './ObligationConditional'

export default function ObligationRow({ obligation, index}){

    useEffect(() => {
        setTokens(obligation && obligation.obligation_short_tokens)
    }, [obligation]) 

    const [short_version, setShortVersion] = useState(false)
    const [tokens, setTokens] = useState(obligation && obligation.obligation_short_tokens)

    const handleShortVersion = () => {
        
        if(short_version){
            setTokens(obligation.obligation_short_tokens)
        }else{
            setTokens(obligation.obligation_tokens)
        }

        setShortVersion(!short_version)
    }

    return (
        <tr>
            <td style={{ width: '30px' }}>{index + 1}</td>
            <td style={{ width: '30px' }}>{obligation.sentence_score}</td>
            <td style={{ width: '30px' }}>{obligation.sentence_classification}</td>

            <td>
                {
                    obligation.condition !== '' ? <ObligationConditional condition={obligation.condition} /> : <></>
                }

                {
                    tokens.map(token => {

                        if (token.should_break) {
                                
                            if (token.toke_type === 'OBLIGATIONS') {
                                return <>
                                    <div className="ui hidden divider" style={{ margin: '0.3rem 0' }}></div>
                                    <i class="angle right icon"></i>
                                    <span style={
                                        { 
                                            color: '#8d121a', 
                                            backgroundColor: 'rgba(141, 18, 26, 0.1)',
                                            textDecoration: token.underline ? 'underline' : 'none'
                                        }}>{token.text}</span>
                                </>
                            } else {
                                return <>
                                    <div className="ui hidden divider" style={{ margin: '0.3rem 0'}}></div>
                                    <i class="angle right icon"></i>
                                    <span style={
                                        {
                                            textDecoration: token.underline ? 'underline' : 'none'
                                        }}>{token.text}</span>
                                </>
                            }
                        } else {
                            if (token.toke_type === 'OBLIGATIONS') {
                                return <>
                                    <span style={
                                        {
                                            color: '#8d121a', 
                                            backgroundColor: 'rgba(141, 18, 26, 0.1)',
                                            textDecoration: token.underline ? 'underline' : 'none'
                                        }}>{token.text}</span>
                                </>
                            } else {
                                return <span style={
                                    {
                                        textDecoration: token.underline ? 'underline' : 'none'
                                    }}>{token.text}</span>
                            }
                        }

                    })
                }

                <span onClick={handleShortVersion}>
                    {
                        obligation.obligation_short_tokens.length < obligation.obligation_tokens.length 
                            ? ' [...]'
                            : ''
                    }
                    </span>
            </td>
        </tr>
    )
}