import React, {useState, useContext} from 'react'
import { Button, Modal  } from 'semantic-ui-react'
import { makeSuccessToast } from '../../makeToast'
import axios from 'axios'
import {AgreementContext} from '../../Agreement/Agreement'
import produce from "immer";

export default function AddFramework() {
    
    const [agreement, handleSetAgreement] = useContext(AgreementContext)

    const [isOpen, setIsOpen] = useState(false)
    const [title, setTitle] = useState('')
    const [caption, setCaption] = useState('')

    const handleClose = () => {
        setIsOpen(!isOpen)
    }

    const handleSave = () => {

        var data = {
            agreementId: agreement.agreement_id,
            title: title,
            caption: caption
        }

        axios.post(`${window.BASE_URL}/framework/addFramework`, data)
        
            .then(response => {
                
                let new_framework = {
                    id: response.data.framework.pk,
                    title: response.data.framework.fields.title,
                    caption: response.data.framework.fields.caption,
                    clauses: [],
                    separators: []
                }
                
                const new_frameworks = produce(agreement.frameworks, frameworks => {
                    frameworks.push(new_framework);
                });

                handleSetAgreement(agreement => { agreement.frameworks = new_frameworks})

                setIsOpen(false)

                makeSuccessToast('Framework added successfully.')
                
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <Modal onClose={handleClose} open={isOpen} trigger={<Button positive name="add" onClick={e => handleClose(e.target.value)}>Add Framework</Button>}>
            <Modal.Header>Add Framework</Modal.Header>
            <Modal.Content>
                    <div className="ui segment very basic padded">
                        <form className="ui form">
                            <div className=" field">
                                <label>Title</label>
                                <input onChange={e => setTitle(e.target.value)} value={title} type="text" name="title" placeholder="Title" />
                            </div>
                            <div className=" field">
                                <label>Caption</label>
                                <input onChange={e => setCaption(e.target.value)} value={caption} type="text" name="caption" placeholder="Caption" />
                            </div>
                            <input type="hidden" name="agreementId" value="{{ agreement_id }}" />
                        </form>
                    </div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose} negative>
                    Cancel
                </Button>
                <Button
                    onClick={handleSave}
                    positive
                    labelPosition='right'
                    icon='checkmark'
                    content='Save'
                />
            </Modal.Actions>
        </Modal>
    )
}
