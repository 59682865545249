import React from 'react'
import {produce} from 'immer'
import axios from 'axios'

export default function CharacteristicListItem({ clause, characteristic, handleSetOnEdit, handleEditClause }) {

    const handleSetOnCurrentEdit = () => {
        handleSetOnEdit(characteristic)
    }

    const handleDelete = () => {

        var data = {
            id: characteristic.id
        }

        axios.post(`${window.BASE_URL}/characteristic/delete`, data)
            .then(function (response) {

                const updated_clause = produce(clause, draftClause => {
                    const new_characteristics = draftClause.characteristics.filter(c => c.id !== characteristic.id)
                    draftClause.characteristics = new_characteristics
                })

                handleEditClause(updated_clause)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div className="item">
            <div className="right floated content">
                <div className="ui mini positive button" onClick={handleSetOnCurrentEdit}>Edit</div>
                <div className="ui mini negative button" onClick={handleDelete}>Delete</div>
            </div>
            <label>{characteristic.title}</label>
        </div >
    )
}