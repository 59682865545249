import React from 'react'

export default function FrameworkClauseMatchItem({ clause, matched, clause_rules_matched }) {

    let has_match = matched && matched.some(m => m.match_id === clause.id)
    let has_rule = clause_rules_matched && clause_rules_matched.some(m => m[0][0] === clause.id)

    if (has_match && has_rule) {
        return (
            <>
                <td className="positive">{clause.verb} {clause.object}</td>
                <td className="positive center aligned"><i className="checkmark icon"></i></td>
                <td className="positive center aligned"><i className="checkmark icon"></i></td>
            </>
        )
    }

    if (has_match || has_rule) {
        return (
            <>
                <td className="warning">{clause.verb} {clause.object}</td>

                {
                    has_rule
                        ? <td className="positive center aligned"><i className="checkmark icon"></i></td>
                        : <td className="negative center aligned"><i className="ban icon"></i></td>
                }

                {
                    has_match
                        ? <td className="positive center aligned"><i className="checkmark icon"></i></td>
                        : <td className="negative center aligned"><i className="ban icon"></i></td>
                }

            </>
        )
    }

    return (
        <>
            <td className="negative">{clause.verb} {clause.object}</td>
            <td className="negative center aligned"><i className="ban icon"></i></td>
            <td className="negative center aligned"><i className="ban icon"></i></td>
        </>
    )
}