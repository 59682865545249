import React, { useState } from 'react'
import axios from 'axios'

export default function AddSentence({ clause, handleAddSentence }) {

    const [show_form, setShowForm] = useState(false)

    const [form, setForm] = useState({})

    function handleChange(e) {
        const value = e.target.value;
        setForm({
            ...form,
            [e.target.name]: value
        });
    }

    const handleOnClick = () => {
        setShowForm(!show_form)
        setForm({})
    }

    const handleSave = () => {

        var data = {
            clause_id: clause.id,
            ...form
        }

        axios.post(`${window.BASE_URL}/sentence/add`, data)
            .then(function (response) {

                handleAddSentence(clause, response)

                setForm({})
                setShowForm(!show_form)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div className='ui grid'>
            <div className='ui sixteen wide column'>
                {
                    show_form ?
                        <>
                            <form className='ui form'>
                                <div className=" field">
                                    <label>Title</label>
                                    <input onChange={handleChange} value={form.title} type="text" name="title" placeholder="Title" />
                                </div>
                                <div className=" field">
                                    <label>Content</label>
                                    <input onChange={handleChange} value={form.content} type="text" name="content" placeholder="Content" />
                                </div>
                            </form>
                            <div className='ui basic segment'>
                                <button onClick={handleSave} className='ui basic positive button right floated'>Add</button>
                                <button onClick={handleOnClick} className='ui basic negative button right floated'>Cancel</button>
                            </div>
                        </>
                        : <div className='ui basic segment'>
                            <button onClick={handleOnClick} className='ui basic positive button right floated'>Add Sentence</button>
                        </div>

                }
            </div>
        </div>
    )
}