import React, {useState} from 'react'
import CharacteristicList from './CharacteristicList'
import AddCharacteristic from './AddCharacteristic'
import EditCharacteristic from './EditCharacteristic'
export default function CharacteristicContainer({ clause, handleEditClause }){

    const [on_add, setOnAdd] = useState(false)
    const [on_edit, setOnEdit] = useState(false)
    const [current_edit_characteristic, setCurrentEditCharacteristic] = useState({})

    const handleSetOnAdd = () => {
        setOnAdd(!on_add)
    }

    const handleSetOnEdit = (characteristic) => {
        setCurrentEditCharacteristic(characteristic)
        setOnEdit(!on_edit)
    }

    if (on_add) {
        return (
            <AddCharacteristic handleEditClause={handleEditClause} handleSetOnAdd={handleSetOnAdd} clause={clause} />
        )
    }

    if (on_edit) {
        return (
            <EditCharacteristic clause={clause} handleEditClause={handleEditClause} characteristic={current_edit_characteristic} handleSetOnEdit={handleSetOnEdit} />
        )
    }

    return  (
        <>
            <CharacteristicList clause={clause} handleEditClause={handleEditClause} characteristics={clause.characteristics} handleSetOnEdit={handleSetOnEdit}/>

        <div className='ui grid'>
            <div className='ui sixteen wide column'>
                <div className='ui basic segment'>
                    <button onClick={handleSetOnAdd} className='ui basic positive button right floated'>Add Characteristic</button>
                </div>
            </div>
        </div>
        </>
    )
}