import React, {useState, useEffect, useContext} from 'react'
import { Button, Modal } from 'semantic-ui-react'
import axios from 'axios'
import { makeSuccessToast } from '../makeToast'
import { AgreementContext } from '../Agreement/Agreement'

export default function ImportClause({ framework }){

    const [agreement, handleSetAgreement] = useContext(AgreementContext)

    const [isOpen, setIsOpen] = useState(false)

    const [clauses_to_import, setClausesToImport] = useState([])

    const [list_to_import, setListToImport] = useState([])

    const handleClose = () => {
        setIsOpen(!isOpen)
    }

    useEffect(() => {

        if (isOpen) {
            let data = {
                frameworkId: framework.id
            }

            axios.post(`${window.BASE_URL}/clause/getClausesToImport`, data)
                .then(function (response) {
                    setClausesToImport(response.data.clauses)
                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(function () {
                    // always executed
                });
        } else {
            setClausesToImport([])
            setListToImport([])
        }

    }, [isOpen])

    const handleImport = () => {

        let data = {
            clausesIds: JSON.stringify(list_to_import),
            frameworkId: framework.id,
            agreementId: agreement.agreement_id
        }

        axios.post(`${window.BASE_URL}/clause/importClauses`, data)
            .then(function (response) {
                handleSetAgreement(agreement => response.data.agreement)
                makeSuccessToast('Framework updated with the new clauses.')
                setIsOpen(false)
            })
            .catch(function (error) {
                makeSuccessToast('Something went wrong with your request.')
            });

        setIsOpen(!isOpen)
    }

    const handleChecked = (e) => {

        let already_added = list_to_import.includes(e.target.value)

        if (already_added) {
            let updated = list_to_import.filter(id => id !== e.target.value)
            setListToImport(updated)

        } else {
            setListToImport([...list_to_import, e.target.value])
        }

    }

    return(
        <Modal onClose={handleClose} open={isOpen} trigger={<button className='ui button green basic' name="add" onClick={e => handleClose(e.target.value)}>Clone Clauses</button>}>
            <Modal.Header>Add Clause</Modal.Header>
            <Modal.Content>

                <div className="ui middle aligned divided list">
                    {
                        clauses_to_import && clauses_to_import.map(c =>
                            <div key={c.id} className="item">
                                <div className="ui checkbox">
                                    <input type="checkbox" onChange={handleChecked} value={c.id} />
                                    <label>{c.caption}: {c.verb} {c.object}</label>
                                </div>
                            </div>
                        )
                    }
                </div>

            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose} negative>
                    Cancel
                </Button>
                <Button
                    onClick={handleImport}
                    positive
                    labelPosition='right'
                    icon='checkmark'
                    content='Import'
                />
            </Modal.Actions>
        </Modal>
    )
}