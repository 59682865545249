import { toast } from 'react-toastify'

toast.configure()

export function makeSuccessToast(text) {
    toast(text, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        type: toast.TYPE.SUCCESS
    })
}

export function makeErrorToast(text) {
    toast(text, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        type: toast.TYPE.ERROR
    })
}