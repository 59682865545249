import React, { useContext, useState } from 'react'
import axios from 'axios'

import { ShouldUpdateSidebarAgreementListContext } from '../../App'

export default function AgreementListItem({ agreement, handleDeletedAgreement }) {

    const [should_update_agreement_list, handleSetShouldUpdateAgreementList] = useContext(ShouldUpdateSidebarAgreementListContext)

    const [on_edit_mode, setOnEditMode] = useState(false)
    const [title, setTitle] = useState(agreement.fields.title)

    const handleOnChangeTitle = (e) => {
        setTitle(e.target.value)
    }

    const handleDelete = () => {

        var data = {
            id: agreement.pk
        }

        axios.post(`${window.BASE_URL}/agreement/delete`, data)
            .then(function (response) {
                handleDeletedAgreement(agreement.pk)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleEdit = (e) => {
        setOnEditMode(!on_edit_mode)
    }

    const handleSave = () => {

        var data = {
            id: agreement.pk,
            title: title
        };

        axios.post(`${window.BASE_URL}/agreement/edit`, data)
            .then(function (response) {

                handleSetShouldUpdateAgreementList()

                setOnEditMode(!on_edit_mode)

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div className="item">
            <div className="right floated content">
                <div onClick={handleDelete} className="ui mini negative button">Delete</div>
            </div>

            <div className="right floated content">
                {
                    on_edit_mode
                        ? <div onClick={handleSave} className="ui mini green button">Save Name</div>
                        : <div onClick={handleEdit} className="ui mini green button">Edit Name</div>
                }
            </div>

            {
                on_edit_mode
                    ? <div className="content"><input onChange={handleOnChangeTitle} value={title} /></div>
                    : <div className="content"><span>{title}</span></div>
            }

        </div>
    )
}