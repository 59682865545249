import React, { useEffect, useState } from 'react'
import EditClausePopup from '../Clause/EditClausePopup'
import axios from 'axios'
import produce from 'immer'
import ClauseListItem from './ClauseListItem'
import DeleteRealClause from './DeleteRealClause'
import SentenceContainer from '../Sentence/SentenceContainer'
import { useImmer } from "use-immer";

export default function ClauseList() {

    const [clauses, setClauses] = useImmer([])

    useEffect(() => {
        axios.post(`${window.BASE_URL}/clause/get_all`)
            .then(function (response) {
                setClauses(clause => response.data.clauses);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])

    const handleEditClause = (clause) => {
        const updated_clauses = produce(clauses, draftClauses => {
            draftClauses.map(c => {
                if(c.id === clause.id){
                    return Object.assign(c, clause)
                }
            })
        })

        setClauses(clause => updated_clauses)
    }

    const handleDeleteClause = (clause) => { 
        const updated_clauses = clauses.filter(c => c.id !== clause.id)
        setClauses(clause => updated_clauses)
    }
    
    return (
        <div id="main-content" style={{ marginTop: '120px' }}>
            <div className="ui container">
                <div className="ui relaxed divided list">
                    {
                        clauses && clauses.map(c =>
                            <EditClausePopup 
                                DeleteClauseButton={<DeleteRealClause clause={c} handleDeleteClause={handleDeleteClause}/>} 
                                key={c.id} 
                                clause={c} 
                                handleEditClause={handleEditClause} 
                                handleDeleteClause={handleDeleteClause}
                                SentenceContainer={<SentenceContainer clause={c} setClauses={setClauses}/>}>

                                <ClauseListItem clause={c} />
                            </EditClausePopup>
                        )
                    }
                </div>
            </div>
        </div>
    )
}