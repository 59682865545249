import React from 'react'

export default function TokenDisplayer({ tokens }) {

    return (
        <div className='ui segment'>
            {
                tokens && tokens.map(t => {
                    
                    if (t.text.includes('\n')) {
                        return <br />
                    }

                    if (t.break_options.active) {

                        if (t.break_options.break_type == 'END_SENTENCE'){
                            return <><span>{t.text}</span><div className="ui hidden divider"></div></>
                        }

                        if (t.break_options.break_type == 'SUB_LITERAL'){
                            return <><div className="ui hidden divider" style={{margin: '0.3rem 0'}}></div><span>{t.text}</span></>
                        }
                    }

                    if (t.active && t.type === 'ENTITY') {
                        return <span style={{ color: '#128D15', backgroundColor: 'rgba(18, 141, 21, 0.1)' }}><b>{t.text}</b></span>
                    }

                    if (t.active && t.type === 'DEFINED_TERM') {
                        return <span style={{ color: '#4287f5', backgroundColor: 'rgba(66, 135, 245, 0.1)' }}><b>{t.text}</b></span>
                    }

                    if (t.active && t.type === 'OBLIGATIONS') {

                        if (t.sub_type === 'SUBJECT') {
                            return <span style={{ borderBottom: '2px dotted red' }}>{t.text}</span>
                        }

                        if (t.sub_type === 'OBJECT') {
                            return <span style={{ borderBottom: '2px dotted blue' }}>{t.text}</span>
                        }

                        if (t.sub_type === 'VERB') {
                            return <span style={{ color: '#8d121a', backgroundColor: 'rgba(141, 18, 26, 0.1)' }}><b>{t.text}</b></span>
                        }

                    }

                    if (t.active && t.type === 'LEGALEASE') {
                        return <span style={{ color: '#99660c', backgroundColor: 'rgba(153, 102, 12, 0.1)' }}><b>{t.text}</b></span>
                    }

                    if (t.active && t.type === 'NEGATIVE') {
                        return <span style={{ color: '#F1F1F1', backgroundColor: 'rgba(141, 18, 26, 0.7)' }}><b>{t.text}</b></span>
                    }

                    return <span>{t.text}</span>

                })
            }
        </div>
    )
}