import React from 'react'
import FrameworkClauseMatchItem from './FrameworkClauseMatchItem'

export default function FrameworkMatchListItem({ framework, matched, clause_rules_matched }) {

    //number of columns => (clause, match)
    const columns = 2
    var perChunk = 2 // items per chunk    

    var inputArray = framework.clauses

    var result = inputArray.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk)

        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
    }, [])

    return (
        <div style={{ marginBottom: '20px' }}>
            <table className="ui celled table">
                <thead>
                    <tr>
                        {
                            Array.from({ length: columns }, (x, i) =>
                                <React.Fragment key={i}>
                                    <th className="six wide">Clause</th>
                                    <th className="one wide center aligned">Rule</th>
                                    <th className="one wide center aligned">Matched</th>
                                </React.Fragment>)
                        }
                    </tr>
                </thead>
                <tbody>
                    
                        {
                            result && result.map((chunk, i) => (
                                <tr key={i}>
                                {chunk && chunk.map((clause, i) => (
                                    <FrameworkClauseMatchItem key={clause.id} clause={clause} matched={matched} clause_rules_matched={clause_rules_matched}/>
                                ))}
                                </tr>
                            ))
                        }
                    
                </tbody>
            </table>
        </div>
    )
}