import React, { useState, useEffect } from 'react'

export default function TermSheet({ review_data }) {

    const [entities, setEntities] = useState([])
    const [characteristics, setCharacteristics] = useState([])

    useEffect(() => {

        let all_ents = []
        let all_chars = []

        review_data.sentences && review_data.sentences.map((s, i) => {
            s.entity_rule_matched_term_sheet && s.entity_rule_matched_term_sheet.map(e => {
                let ent = {
                    entity: e.entity,
                    text_matched: e.text_matched,
                    clause_name: e.clause_name
                }

                all_ents.push(ent)
            })
        })

        review_data.sentences && review_data.sentences.map((s, i) => {
            s.characteristic_rule_matched_term_sheet && s.characteristic_rule_matched_term_sheet.map(c => {
                let char = {
                    characteristic: c.characteristic,
                    text_matched: c.text_matched,
                    clause_name: c.clause_name
                }

                all_chars.push(char)
            })
        })

        setEntities(all_ents)
        setCharacteristics(all_chars)

    }, [review_data])

    return (

        <table className="ui celled table">
            <thead>
                <tr>
                    <th>Entity</th>
                    <th>Match</th>
                    <th>Clause</th>
                </tr>
            </thead>
            <tbody>
                {
                    entities && entities.map((e, i) =>
                        <tr key={i}>
                            <td>{e.entity}</td>
                            <td>{e.text_matched}</td>
                            <td>{e.clause_name}</td>
                        </tr>)
                }

                {
                    characteristics && characteristics.map((c, i) =>
                        <tr key={i}>
                            <td>{c.characteristic}</td>
                            <td>{c.text_matched}</td>
                            <td>{c.clause_name}</td>
                        </tr>)
                }
            </tbody>
        </table>


    )
}