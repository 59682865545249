import React, { useState, useEffect, useContext } from 'react'
import { sortableHandle } from 'react-sortable-hoc';

import { CheckedClausesContext} from '../Framework/FrameworkContainer'

const DragHandle = sortableHandle(() => <div className="ui right floated"><i className="bars icon"></i></div>);

export default function FrameworkClauseItem({ current_clause, onClickHandle }) {

    const [checked_clauses, setCheckedClauses] = useContext(CheckedClausesContext)

    const handleChecked = (e) => {

        let clause_id = parseInt(e.target.value, 10);


        let already_added = checked_clauses.includes(clause_id)

        if (already_added) {
            let updated = checked_clauses.filter(id => id !== clause_id)
            setCheckedClauses(updated)
        } else {
            setCheckedClauses([...checked_clauses, clause_id])
        }
    }
    
    return (

        <div key={current_clause.id} className="item">
            <div className="ui checkbox">
                <input type="checkbox" onChange={handleChecked} value={current_clause.id} defaultChecked={true} className="active-clause-assemble" />
                <label></label>
            </div>
            <div onClick={onClickHandle} className="content actual-clause">

                {
                    current_clause.type === 'action'
                        ? <div style={{ wordBreak: 'break-word' }}>
                            <span className="ui header red">{current_clause.verb}</span>
                            <span className="ui header">&nbsp;{current_clause.object}</span>
                            <DragHandle />
                        </div>
                        : <div style={{ wordBreak: 'break-word' }}>
                            <span className="ui header blue">{current_clause.verb}</span>
                            <span className="ui header">&nbsp;{current_clause.object}</span>
                            <DragHandle />
                        </div>
                }

                <div className="description">
                    <p></p>
                </div>
            </div>
        </div>
    )
}