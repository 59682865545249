import React from 'react'
import { Link } from 'react-router-dom'
import LoginButton from '../Login/LoginButton'
import LogoutButton from '../Login/LogoutButton'
import { useAuth0 } from '@auth0/auth0-react'

function Navbar(){

    const { user } = useAuth0()

    return (
            <div id="top-menu" className="ui top fixed inverted menu">
                <div className="left menu">
                    <div className="item">kResolve</div>
                {user ?
                    <>
                        <Link className="item" to={`/agreement/`}>Agreement List</Link>
                        <Link className="item" to={`/clause/`}>Clause List</Link>
                    </>
                    : <></>
                }
                    
                </div>
                <div className="right menu">
                    {user ? <LogoutButton /> : <LoginButton />}
                    
                    <a href="#" className="item">Help</a>
                </div>
            </div>
            )
}

export default Navbar