import React, { useState } from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css'
import Home from './componenets/HomePage/Home'
import Navbar from './componenets/Navbar/Navbar'
import Sidebar from './componenets/Sidebar/Sidebar'
import Agreement from './componenets/Agreement/Agreement'
import AgreementList from './componenets/Agreement/AgreementList'
import ClauseList from './componenets/Clause/ClauseList'
import AnalysisContainer from './componenets/Analysis/AnalysisContainer'
import Explanation from './componenets/Analysis/Explanation'
import Calculations from './componenets/Analysis/Calculations'
import AnalysisLogos from './componenets/Analysis/AnalysisLogos'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

export const ShouldUpdateSidebarAgreementListContext = React.createContext()

function App() {

  const [should_update_agreement_list, setShouldUpdateAgreementList] = useState(false)
  const handleSetShouldUpdateAgreementList = () => {
    setShouldUpdateAgreementList(!should_update_agreement_list)
  }

  return (
    <Router>
      <div className="App">
        <ShouldUpdateSidebarAgreementListContext.Provider value={[should_update_agreement_list, handleSetShouldUpdateAgreementList]}>

          <Navbar />
          <Sidebar />

          <Switch>
            <Route path="/agreement/:id">
              <Agreement />
            </Route>

            <Route path="/agreement">
              <AgreementList />
            </Route>

            <Route path="/clause">
              <ClauseList />
            </Route>

            <Route path="/cost-estimate" exact>
              <AnalysisContainer>
                <Calculations />
                <Explanation />
                <AnalysisLogos />
              </AnalysisContainer>
            </Route>

            <Route path="/" exact>
              <AnalysisContainer />
            </Route>

            {/* <Route path="/" exact>
              <Home />
            </Route> */}
            
          </Switch>
        </ShouldUpdateSidebarAgreementListContext.Provider>
      </div>
    </Router>
  );
}

export default App;
