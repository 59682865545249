import React, { useState,useEffect } from 'react'
import { Button, Modal, Tab } from 'semantic-ui-react'
import axios from 'axios'
import EntitiesCharacteristicContainer from '../Entities/EntitiesCharacteristicContainer'

export default function EditClausePopup({ clause, handleEditClause, children, SentenceContainer, framework, DeleteClauseButton }) {

    const [isOpen, setIsOpen] = useState(false)
    const [form, setForm] = useState(clause)

    function handleChange(e) {
        const value = e.target.value;
        setForm({
            ...form,
            [e.target.name]: value
        });
    }

    useEffect(() =>{
        setForm({...form, sentences: clause.sentences})
    }, [clause.sentences])

    useEffect(() => {
        setForm({ ...form, entities: clause.entities })
    }, [clause.entities])

    useEffect(() => {
        setForm({ ...form, characteristics: clause.characteristics })
    }, [clause.characteristics])

    const handleSave = () => {

        var data = JSON.stringify(form)

        axios.post(`${window.BASE_URL}/framework/editFrameworkClause`, data)
            .then(function (response) {
                handleEditClause(form)
                setIsOpen(false)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleClose = () => {
        setIsOpen(!isOpen)
    }

    const panes = [

        {
            menuItem: 'Caption and Sentence',
            render: () => (
                <Tab.Pane>
                    <form className="ui form">
                        <div className=" field">
                            <label>Caption</label>
                            <input onChange={handleChange} value={form.caption} type="text" name="caption" placeholder="Caption" />
                        </div>
                    </form>

                    <div className='ui basic segment'>
                        {SentenceContainer && SentenceContainer}
                    </div>
                </Tab.Pane>
            )
        },
        {
            menuItem: 'SVO',
            render: () => (
                <Tab.Pane>
                    <form className="ui form" style={{ minHeight: '220px' }}>
                        <div className=" field">
                            <label>Type</label>
                            <input onChange={handleChange} value={form.type} type="text" name="type" placeholder="Type" />
                        </div>
                        <div className=" field">
                            <label>Verb</label>
                            <input onChange={handleChange} value={form.verb} type="text" name="verb" placeholder="Verb" />
                        </div>
                        <div className=" field">
                            <label>Object</label>
                            <input onChange={handleChange} value={form.object} type="text" name="object" placeholder="Object" />
                        </div>

                        <div className=" field">
                            <label>Extra Verbs To Match</label>
                            <input onChange={handleChange} value={form.verbs_to_match} type="text" name="verbs_to_match" placeholder="Verbs To Match" />
                        </div>

                        <div className=" field">
                            <label>Extra Objects To Match</label>
                            <input onChange={handleChange} value={form.objects_to_match} type="text" name="objects_to_match" placeholder="Objects To Match" />
                        </div>
                    </form>
                </Tab.Pane>
            )
        },
        {
            menuItem: 'Entities and Characteristics',
            render: () => (
                <Tab.Pane>                   
                    
                    <EntitiesCharacteristicContainer handleEditClause={handleEditClause} handleChange={handleChange} clause={form}/>

                </Tab.Pane>
            )
        },
        {
            menuItem: 'Matchers',
            render: () => (
                <Tab.Pane>
                    <form className="ui form" style={{ minHeight: '220px' }}>
                        <div className=" field">
                            <label>Rule</label>
                            <input onChange={handleChange} value={form.rule} type="text" name="rule" placeholder="Rule" />
                        </div>
                        <div className=" field">
                            <label>Status</label>
                            <input onChange={handleChange} value={form.status} type="text" name="status" placeholder="Status" />
                        </div>
                    </form>
                </Tab.Pane>
            )
        },
    ]

    return (<Modal onClose={handleClose} open={isOpen} trigger={React.cloneElement(children, { onClickHandle: handleClose })}>
        <Modal.Header>{clause.verb} {clause.object}</Modal.Header>
        <Modal.Content>

            <Tab panes={panes} />

        </Modal.Content>
        <Modal.Actions>
            <div className="ui grid">
                <div className="ui two wide column">

                    {DeleteClauseButton && DeleteClauseButton}

                </div>
                <div className="ui seven wide column right floated">
                    <Button onClick={handleClose} negative>Cancel</Button>
                    <Button
                        onClick={handleSave}
                        positive
                        labelPosition='right'
                        icon='checkmark'
                        content='Save'
                    />
                </div>
            </div>
        </Modal.Actions>
    </Modal>)
}