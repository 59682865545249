import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import FrameworkContainer from '../Framework/FrameworkContainer'
import { useParams } from 'react-router-dom'
import { Dimmer, Loader } from 'semantic-ui-react'
import { useImmer } from "use-immer";
import { makeErrorToast } from '../makeToast'

export const AgreementContext = React.createContext()

export default function Agreement() {
    
    const [agreement, handleSetAgreement] = useImmer({})

    const { id } = useParams()

    const [dimmer_active, setDimmerActive] = useState(true)

    useEffect(() => {

        setDimmerActive(true)

        axios.get(`${window.BASE_URL}/agreement/json/${id}`)
            .then(function (response) {
                setDimmerActive(false)
                handleSetAgreement(agreement => response.data.agreement)
                document.title = response.data.agreement.agreement_title
            })
            .catch(function (error) {
                console.log(error);
                makeErrorToast('Something went wrong when getting this agreement.')
                setDimmerActive(false)
            })
            .finally(function () {
                setDimmerActive(false)
                // always executed
            });
    }, [id])

    return (
        <div style={{ marginTop: '120px', marginLeft: '20rem', marginRight: '5rem', minWidth:'800px'}}>
            <AgreementContext.Provider value={[agreement, handleSetAgreement]}>
            <div>
                <Dimmer.Dimmable dimmed={dimmer_active}>
                    <Dimmer active={dimmer_active} inverted>
                        <Loader>Loading</Loader>
                    </Dimmer>
                    
                    <div className="ui segment basic">
                        <h2 className="ui header">
                            <div id="agreement-title" data-agreement-id="{{ agreement_id}}" className="content" style={{ borderRadius: '5px', marginTop: '2px' }}>
                                {agreement.agreement_title}
                            </div>

                            <button id="save-title-framework" style={{ padding: '10px 10px 10px 18px', display: 'none' }} className="ui basic positive button"><i className="save icon"></i></button>
                            <button id="cancel-edit-title-framework" style={{ padding: '10px 10px 10px 18px', display: 'none' }} className="ui basic negative button"><i className="times icon"></i></button>

                            <div className="sub header">{agreement.agreement_title}</div>
                        </h2>
                    </div>

                    <FrameworkContainer />
                </Dimmer.Dimmable>
            </div>
            </AgreementContext.Provider>
        </div>
    )
}