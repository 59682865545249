import React from 'react'
import { Link } from 'react-router-dom'
import { Accordion } from 'semantic-ui-react'

export default function SideBarAgreementListItem({ agreement, active }) {

    return (
        <Accordion.Content active={active}>
            <p><Link to={`/agreement/${agreement.pk}`}>{agreement.fields.title}</Link></p>
        </Accordion.Content>
    )
}