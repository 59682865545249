import React, { useState, useEffect, useContext } from 'react'
import { Tab, Segment } from 'semantic-ui-react'
import FrameworkList from './NonReviewMode/FrameworkList'
import FrameworkReview from './ReviewMode/FrameworkReview'
import FrameworkMenu from './NonReviewMode/FrameworkMenu'
import ClauseAssembly from './NonReviewMode/ClauseAssembly'
import {AgreementContext} from '../../componenets/Agreement/Agreement'

export const CheckedClausesContext = React.createContext()

function FrameworkContainer() {
    
    const [checked_clauses, setCheckedClauses] = useState([])
    const [editor_html_content, setEditorHTMLContent] = useState('')
    const [editor_text_content, setEditorTextContent] = useState('')
    const [agreement, handleSetAgreement] = useContext(AgreementContext)

    useEffect(() => {

        let checked_c = []

        agreement.frameworks && agreement.frameworks.map( f => 
            f.clauses && f.clauses.map(c => 
                checked_c.push(c.id)
            )
        )

        setCheckedClauses(checked_c)
    }, [agreement])

    const handleSetEditorHTMLContent = (content) =>{
        setEditorHTMLContent(content)
    } 

    const handleSetEditorTextContent = (content) => {
        setEditorTextContent(content)
    } 

    const panes = [

        {
            menuItem: 'Framework',
            render: () => (
                <Tab.Pane as='div'>
                    <div className='ui basic segment'>
                        <FrameworkMenu />
                    </div>
                    
                    <FrameworkList frameworks_list={agreement.frameworks} />
                </Tab.Pane>
            )
        },
        {
            menuItem: 'Assembly',
            render: () => (
                <Tab.Pane as='div'>
                    <ClauseAssembly checked_clauses={checked_clauses} />
                </Tab.Pane>
            )
        },
        {
            menuItem: 'Review',
            render: () => (
                <Tab.Pane as='div'>
                    <FrameworkReview 
                        editor_html_content={editor_html_content} 
                        handleSetEditorHTMLContent={handleSetEditorHTMLContent}
                        editor_text_content={editor_text_content}
                        handleSetEditorTextContent={handleSetEditorTextContent}/>
                </Tab.Pane>
            )
        }
    ]

    return (
        <div>
            <CheckedClausesContext.Provider value={[checked_clauses, setCheckedClauses]}>
                <Tab menu={{ borderless: true, attached: false, tabular: false, style: { fontSize: '1.3rem'} }} panes={panes} />
            </CheckedClausesContext.Provider>
        </div>
    )
}

export default FrameworkContainer