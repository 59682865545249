import React, { useState } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'

export default function Calculations({ calculated_cost }) {

    const [active, setActive] = useState(false)

    const handleActive = () => {
        setActive(!active)
    }

    return (
        <div className='ui segment basic'>
            <Accordion fluid styled>
                <Accordion.Title active={active} index={0} onClick={handleActive}>
                    <Icon name='dropdown' />Calculations</Accordion.Title>
                <Accordion.Content active={active}>
                    <table className='ui celled table'>
                        <thead>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Reading Time (Hours)</td>
                                <td>{calculated_cost ? calculated_cost.reading_time : 0}</td>
                            </tr>
                            <tr>
                                <td>Number of Reviews</td>
                                <td>{calculated_cost ? calculated_cost.number_of_reviews : 0}</td>
                            </tr>
                            <tr>
                                <td>Number of Reviewers Professionals</td>
                                <td>{calculated_cost ? calculated_cost.number_of_reviewers_professionals : 0}</td>
                            </tr>
                            <tr>
                                <td>Payscale</td>
                                <td>${calculated_cost ? calculated_cost.payscale : 0}</td>
                            </tr>
                            <tr>
                                <td>Estimated Cost</td>
                                <td>${calculated_cost ? calculated_cost.estimated_cost : 0}</td>
                            </tr>
                        </tbody>
                    </table>
                </Accordion.Content>
            </Accordion>
        </div>
    )
}