import React, {useEffect, useState, useContext} from 'react'
import { Button, Modal } from 'semantic-ui-react'
import SeparatorItem from './SeparatorItem'
import axios from 'axios'
import {AgreementContext} from '../../componenets/Agreement/Agreement'

export default function EditSeparator({ separator }){

    const [agreement, handleSetAgreement] = useContext(AgreementContext)

    const [form, setForm] = useState(separator)
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        setForm(separator)
    }, [separator])

    function handleChange(e) {
        const value = e.target.value;
        setForm({
            ...form,
            [e.target.name]: value
        });
    }

    const handleClose = () => {
        setIsOpen(!isOpen)
    }

    const handleDelete = () => {

        var data = {
            separator_id: separator.id
        }

        axios.post(`${window.BASE_URL}/clauseseparators/delete_separator`, data)
            .then(function (response) {

                setIsOpen(false)

                handleSetAgreement(agreement => {
                    agreement.frameworks.map(function (f, i) {
                        if (f.id === separator.framework_id) {
                            f.separators = f.separators.filter(s => s.id !== separator.id)
                        }
                    })
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleSave = () => {

        var data = JSON.stringify(form);

        axios.post(`${window.BASE_URL}/clauseseparators/edit_separator`, data)
            .then(function (response) {

                handleSetAgreement(agreement => {    
                    agreement.frameworks.map(function (f, i) {
                        if (f.id === separator.framework_id) {
                            f.separators.map(function (s, i) {
                                if (s.id === separator.id) {
                                    s.title = form.title
                                }

                            })
                        }
                    })
                })

                setIsOpen(false)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return(
        <Modal onClose={handleClose} open={isOpen} trigger={<SeparatorItem separator={separator} name="add" onClickHandle={handleClose}>Add Clause</SeparatorItem>}>
            <Modal.Header>Edit Separator</Modal.Header>
            <Modal.Content>
                <div className="ui segment very padded basic">
                    <div className="ui segment padded">
                        <form className="ui form">
                            <div className=" field">
                                <label>Title</label>
                                <input onChange={handleChange} value={form.title} type="text" name="title" placeholder="title" />
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <div className="ui grid">
                    <div className="ui two wide column">
                        <div onClick={handleDelete} className="ui negative labeled icon button">
                            Delete<i className="trash icon"></i>
                        </div>
                    </div>
                    <div className="ui seven wide column right floated">
                        <Button onClick={handleClose} negative>Cancel</Button>
                        <Button
                            onClick={handleSave}
                            positive
                            labelPosition='right'
                            icon='checkmark'
                            content='Save'
                        />
                    </div>
                </div>
            </Modal.Actions>
        </Modal>
    )
}