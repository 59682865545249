import React, { useState } from 'react'
import AddEntitie from './AddEntitie'
import EntitiesList from './EntitiesList'
import EditEntity from './EditEntity'

export default function EntititesContainer({ clause, handleEditClause }) {

    const [on_add, setOnAdd] = useState(false)
    const [on_edit, setOnEdit] = useState(false)
    const [current_edit_entity, setCurrentEditEntity] = useState({})

    const handleSetOnAdd = () => {
        setOnAdd(!on_add)
    }

    const handleSetOnEdit = (entity) => {
        setCurrentEditEntity(entity)
        setOnEdit(!on_edit)
    }

    if (on_add) {
        return (
            <AddEntitie handleEditClause={handleEditClause} handleSetOnAdd={handleSetOnAdd} clause={clause} />
        )
    }

    if (on_edit) {
        return (
            <EditEntity clause={clause} handleEditClause={handleEditClause} entity={current_edit_entity} handleSetOnEdit={handleSetOnEdit} />
        )
    }

    return (
        <>
            <EntitiesList clause={clause} handleEditClause={handleEditClause} entities={clause.entities} handleSetOnEdit={handleSetOnEdit} />

            <div className='ui grid'>
                <div className='ui sixteen wide column'>
                    <div className='ui basic segment'>
                        <button onClick={handleSetOnAdd} className='ui basic positive button right floated'>Add Entity</button>
                    </div>
                </div>
            </div>
        </>
    )
}