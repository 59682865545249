import React, { useState } from 'react'
import { Accordion, Icon, Input } from 'semantic-ui-react'
import Toggle from 'react-toggle'

export default function Explanation({ explanation_form, editExplanationForm, handleReCalculate, display_recalculate}) {

    const [active, setActive] = useState(false)

    const handleActive = () => {
        setActive(!active)
    }

    return (
        <div className='ui segment basic'>
            <Accordion fluid styled>
                <Accordion.Title active={active} index={0} onClick={handleActive}>
                    <Icon name='dropdown' />Explanation</Accordion.Title>
                <Accordion.Content active={active}>

                    <table className='ui celled table'>
                        <thead>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ backgroundColor: 'rgba(66, 135, 245, 0.1)' }} colspan="2"><b>Base Values</b></td>
                            </tr>
                            <tr>
                                <td>Base Reading Grade</td>
                                <td className="four wide">
                                    <div class="ui fluid input mini">
                                        <input
                                            name='base_reading_grade'
                                            onChange={editExplanationForm}
                                            type="text"
                                            value={explanation_form.base_reading_grade}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Base reading words per minute</td>
                                <td className="four wide">
                                    <div class="ui fluid input mini">
                                        <input 
                                            name='base_reading_words_per_minute'
                                            onChange={editExplanationForm}
                                            type="text" 
                                            value={explanation_form.base_reading_words_per_minute} 
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Base Payscale</td>
                                <td className="four wide">
                                    <div class="ui fluid input mini">
                                        <input 
                                            name='base_payscale'
                                            onChange={editExplanationForm}
                                            type="text" 
                                            value={explanation_form.base_payscale} 
                                        />
                                    </div>
                                </td>
                            </tr>
                            
                            <tr>
                                <td style={{ backgroundColor: 'rgba(66, 135, 245, 0.1)' }} colspan="2">
                                    <b>Cost Multipliers for each grade increase</b>
                                </td>
                            </tr>

                            <tr>
                                <td>Decrease in reading speed</td>
                                <td className="four wide">
                                    <div class="ui fluid input mini">
                                        <input 
                                            name='reading_speed_decrease_per_grade_level'
                                            onChange={editExplanationForm}
                                            type="text" 
                                            value={explanation_form.reading_speed_decrease_per_grade_level} 
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Increase in number of Reviews</td>
                                <td className="four wide">
                                    <div class="ui fluid input mini">
                                        <input 
                                            name='reviews_per_grade'
                                            onChange={editExplanationForm}
                                            type="text" 
                                            value={explanation_form.reviews_per_grade} 
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Increase in number of Reviews/Administrators</td>
                                <td className="four wide">
                                    <div class="ui fluid input mini">
                                        <input 
                                            name='reviewver_administrator_increase_per_grade'
                                            onChange={editExplanationForm}
                                            type="text" 
                                            value={explanation_form.reviewver_administrator_increase_per_grade} 
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Increase in Payscale</td>
                                <td className="four wide">
                                    <div class="ui fluid input mini">
                                        <input 
                                            name='payscale_increase_per_grade'
                                            onChange={editExplanationForm}
                                            type="text" 
                                            value={explanation_form.payscale_increase_per_grade} 
                                        />
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ backgroundColor: 'rgba(66, 135, 245, 0.1)' }} colspan="2"><b>Adjustment Factors</b></td>
                            </tr>

                            <tr>
                                <td>Contract requires active management</td>
                                <td>
                                    <Toggle
                                        checked={explanation_form.agreement_require_active_management}
                                        defaultChecked={explanation_form.agreement_require_active_management}
                                        icons={{
                                            checked: <span style={{color: 'white', verticalAlign: 'middle'}}><b>Yes</b></span>,
                                            unchecked: <span style={{ color: 'white', verticalAlign: 'middle' }}><b>No</b></span>,
                                        }}
                                        onChange={editExplanationForm}
                                        name='agreement_require_active_management'
                                        className='custom-classname-grey toggle-with-text'
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Conformity to company standards percentage</td>
                                <td className="four wide">
                                    <div class="ui fluid input mini">
                                        <input 
                                            name='conformity_to_company_standards'
                                            onChange={editExplanationForm}
                                            type="text" 
                                            value={explanation_form.conformity_to_company_standards} placeholder='%' 
                                        />
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>

                    {
                        display_recalculate && <button onClick={handleReCalculate} className="ui basic green button ">Re-Calculate</button>
                    }

                </Accordion.Content>
            </Accordion>
        </div>
    )
}