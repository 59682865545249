import React from 'react'

export default function AnalizedSentences({ sentences, readability_avg}) {

    return (
        <div className="ui segment basic" style={{ overflow: 'scroll', height: '800px' }}>
            
            <table className="ui celled table">
                <thead>
                    <tr><th>Caption Found</th>
                        <th className="four wide">Sentence</th>
                        <th>Subject</th>
                        <th>Verb</th>
                        <th>Object</th>
                        <th>Entities</th>
                        <th>Readability</th>
                        <th>Clause Splitter</th>
                        <th>Terms</th>
                        <th>Classification</th>
                        <th>Score</th>
                        <th>Subject Matter</th>
                        <th>Clause Rule Matched</th>
                    </tr></thead>
                <tbody>
                    {
                        sentences && sentences.map((sentence, index) =>

                            <tr key={index} className={sentence.sentence_classifier_rule_matched ? '' : 'negative'}>
                                <td>{sentence.caption}</td>
                                <td className="four wide">{sentence.text}</td>
                                <td>{sentence.svo[0]}</td>
                                <td>{sentence.svo[1]}</td>
                                <td>{sentence.svo[2]}</td>
                                <td>
                                    <ul>
                                        {
                                            sentence.entities && sentence.entities.map((item, i) => (
                                                <li key={i}>{item.text}: {item.label}</li>
                                            ))
                                        }
                                    </ul>
                                </td>
                                <td>{sentence.readability.toFixed(2)}</td>
                                <td>
                                    <ul>
                                        {
                                            sentence.splitted_clauses && sentence.splitted_clauses.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))
                                        }
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        {
                                            sentence.classification_names && sentence.classification_names.map((r, i) =>
                                                <li key={i}>{r}</li>
                                            )
                                        }
                                    </ul>
                                </td>
                                <td>
                                    {sentence.sentence_classification}
                                </td>
                                <td>
                                    {sentence.sentence_score}
                                </td>
                                <td>
                                    <ul>
                                        {
                                            sentence.subject_matter && sentence.subject_matter.map((sm, i) =>
                                                <li key={i}>{sm}</li>
                                            )
                                        }
                                    </ul>
                                </td>
                                <td>
                                    {sentence.clause_rule_matched_name}
                                </td>
                            </tr>
                        )
                    }
                    
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total Avg: {readability_avg}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
        
    )
}