import React, {useState, useContext} from 'react'
import { Button, Modal, Tab } from 'semantic-ui-react'
import axios from 'axios'

import {AgreementContext} from '../../componenets/Agreement/Agreement'

export default function AddClause({framework}) {

    const [agreement, handleSetAgreement] = useContext(AgreementContext)

    const [isOpen, setIsOpen] = useState(false)

    const [form, setForm] = useState({
        type: '',
        caption: '',
        sentence: '',
        verb: '',
        object: '',
        verbs_to_match: '',
        objects_to_match: '',
        rule: '',
        status: '',
        frameworkId: framework.id
    })

    function handleChange(e) {
        const value = e.target.value;
        setForm({
            ...form,
            [e.target.name]: value
        });
    }

    const handleClose = () => {
        setIsOpen(!isOpen)
    }

    const handleSave = () => {

        axios.post(`${window.BASE_URL}/framework/addFrameworkClause`, form)
            .then(function (response) {

                const new_clause = {
                    id: response.data.clause.pk,
                    type: response.data.clause.fields.type,
                    caption: response.data.clause.fields.caption,
                    verb: response.data.clause.fields.verb,
                    object: response.data.clause.fields.object,
                    verbs_to_match: response.data.clause.fields.verbs_to_match,
                    objects_to_match: response.data.clause.fields.objects_to_match,
                    rule: response.data.clause.fields.rule,
                    framework_id: response.data.clause.fields.framework,
                    sort_order: response.data.clause.fields.sort_order,
                    status: response.data.clause.fields.status,
                    sentences: [],
                    characteristics: [],
                    entities: []
                }

                handleSetAgreement(agreement => {
                    agreement.frameworks.map(f => {
                        if (f.id === framework.id) {
                            const clauses = f.clauses
                            clauses.push(new_clause)
                            f.clauses = clauses
                        }
                    })
                })

                setIsOpen(false)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const panes = [

        {
            menuItem: 'Caption and Sentence',
            render: () => (
                <Tab.Pane>
                    <form className="ui form">
                        <div className=" field">
                            <label>Caption</label>
                            <input onChange={handleChange} value={form.caption} type="text" name="caption" placeholder="Caption" />
                        </div>
                    </form>

                </Tab.Pane>
            )
        },
        {
            menuItem: 'SVO',
            render: () => (
                <Tab.Pane>
                    <form className="ui form" style={{ minHeight: '220px' }}>
                        <div className=" field">
                            <label>Type</label>
                            <input onChange={handleChange} value={form.type} type="text" name="type" placeholder="Type" />
                        </div>
                        <div className=" field">
                            <label>Verb</label>
                            <input onChange={handleChange} value={form.verb} type="text" name="verb" placeholder="Verb" />
                        </div>
                        <div className=" field">
                            <label>Object</label>
                            <input onChange={handleChange} value={form.object} type="text" name="object" placeholder="Object" />
                        </div>

                        <div className=" field">
                            <label>Extra Verbs To Match</label>
                            <input onChange={handleChange} value={form.verbs_to_match} type="text" name="verbs_to_match" placeholder="Verbs To Match" />
                        </div>

                        <div className=" field">
                            <label>Extra Objects To Match</label>
                            <input onChange={handleChange} value={form.objects_to_match} type="text" name="objects_to_match" placeholder="Objects To Match" />
                        </div>
                    </form>
                </Tab.Pane>
            )
        },
        {
            menuItem: 'Qualifications and Exceptions',
            render: () => (
                <Tab.Pane>
                    <form className="ui form" style={{ minHeight: '220px' }}>

                    </form>
                </Tab.Pane>
            )
        },
        {
            menuItem: 'Matchers',
            render: () => (
                <Tab.Pane>
                    <form className="ui form" style={{ minHeight: '220px' }}>
                        <div className=" field">
                            <label>Rule</label>
                            <input onChange={handleChange} value={form.rule} type="text" name="rule" placeholder="Rule" />
                        </div>
                        <div className=" field">
                            <label>Status</label>
                            <input onChange={handleChange} value={form.status} type="text" name="status" placeholder="Status" />
                        </div>
                    </form>
                </Tab.Pane>
            )
        },
    ]

    return (
        <Modal onClose={handleClose} open={isOpen} trigger={<button className='ui button green basic' name="add" onClick={e => handleClose(e.target.value)}>Add Clause</button>}>
            <Modal.Header>Add Clause</Modal.Header>
            <Modal.Content>

                <Tab panes={panes} />

            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose} negative>
                    Cancel
                </Button>
                <Button
                    onClick={handleSave}
                    positive
                    labelPosition='right'
                    icon='checkmark'
                    content='Save'
                />
            </Modal.Actions>
        </Modal>
    )
}
