import React from 'react'
import EntitiesListItem from './EntitiesListItem'

export default function EntitiesList({ clause, entities, handleSetOnEdit, handleEditClause }) {
    return (

        <div className='ui basic segment'>
            <div className='ui header'>Entities</div>

            <div className='ui grid'>
                <div className='ui sixteen wide column'>
                    <div className="ui middle aligned divided list">
                        {
                            entities && entities.map(e =>
                                <EntitiesListItem clause={clause} handleEditClause={handleEditClause} key={e.id} entity={e} handleSetOnEdit={handleSetOnEdit} />
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}