import React, { useState, useContext } from 'react'
import { SentenceEditModeActiveContext } from './SentenceContainer'
import axios from 'axios'

export default function EditSentence({ sentence, handleEditSentence }) {

    const [on_sentence_edit_mode, handleOnSentenceEditActiveMode] = useContext(SentenceEditModeActiveContext)

    const [sentence_form, setSentenceForm] = useState(sentence)

    function handleChange(e) {
        const value = e.target.value;
        setSentenceForm({
            ...sentence_form,
            [e.target.name]: value
        });
    }

    const handleSave = () => {

        var data = {
            sentence_id: sentence.id,
            ...sentence_form
        }

        axios.post(`${window.BASE_URL}/sentence/edit`, data)
            .then(function (response) {

                handleEditSentence(sentence, sentence_form)

                handleParentEditMode()
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleParentEditMode = () => {
        handleOnSentenceEditActiveMode()
    }

    return (
        <div style={{ marginBottom: '20px' }}>
            <form className='ui form'>
                <div className=" field">
                    <label>Title</label>
                    <input onChange={handleChange} value={sentence_form.title} type="text" name="title" placeholder="Title" />
                </div>
                <div className=" field">
                    <label>Content</label>
                    <input onChange={handleChange} value={sentence_form.content} type="text" name="content" placeholder="Content" />
                </div>
            </form>
            <div className='ui basic segment'>
                <button onClick={handleSave} className='ui basic positive button right floated'>Save</button>
                <button onClick={handleParentEditMode} className='ui basic negative button right floated'>Cancel</button>
            </div>
        </div>
    )
}