import React, { useState } from 'react'
import FrameworkMatchListItem from './FrameworkMatchListItem'

export default function FrameworkMatchList({ frameworks, matched, clause_rules_matched }) {
    return (
        <>  
            {
                frameworks && frameworks.map(framework =>
                    <FrameworkMatchListItem key={framework.id} framework={framework} matched={matched} clause_rules_matched={clause_rules_matched}/>
                )
            }
        </>
    )
}