import React, { useState } from 'react'
import ObligationRow from './ObligationRow'
import { Slider } from "react-semantic-ui-range";

export default function ObligationsAndRights({ obligations_list, svo_summary, agreement_statements }){

    const [slider_value, setSliderValue] = useState([0, 15])

    const [list, setList] = useState(obligations_list)

    const settings = {
        start: [0, 15],
        min: 0,
        max: 15,
        step: 1,
        onChange: value => {
            setSliderValue(value)
            
            const filtered = obligations_list && obligations_list.filter(o => o.sentence_score >= value[0] && o.sentence_score <= value[1])

            setList(filtered)
        }
    };


    return(
        <div>
            <div className='ui grid'>
                <div className='ui sixteen wide column'>
                    <table className="ui celled table">
                        <thead>
                            <tr>
                                <th>Agreement Statement</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                agreement_statements && agreement_statements.map((value, i) =>
                                    <tr key={i}>
                                        <td>{value}</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='ui grid'>
                <div className='ui five wide column'>
                    <table className="ui celled table">
                        <thead>
                            <tr>
                                <th>Subject</th>
                                <th>Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                svo_summary && svo_summary.subjects.map((v, i) =>
                                    <tr key={i}>
                                        <td>{v.text}</td>
                                        <td>{v.count}</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <div className='ui five wide column'>
                    <table className="ui celled table">
                        <thead>
                            <tr>
                                <th>Verb</th>
                                <th>Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                svo_summary && svo_summary.verbs.map((v, i) =>
                                    <tr key={i}>
                                        <td>{v.text}</td>
                                        <td>{v.count}</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <div className='ui five wide column'>
                    <table className="ui celled table">
                        <thead>
                            <tr>
                                <th>Object</th>
                                <th>Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                svo_summary && svo_summary.objects.map((v, i) =>
                                    <tr key={i}>
                                        <td>{v.text}</td>
                                        <td>{v.count}</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            
            <div className='ui grid'>
                <div className='ui five wide column'>
                    <div className='ui segment'>
                        <div className='sub header'>Showing Score from {slider_value[0]} to {slider_value[1]}</div>
                        <Slider value={slider_value} color="black" settings={settings} discrete multiple/>
                    </div>
                </div>
            </div>
            
            <div className="ui segment basic" style={{ overflow: 'scroll', height: '800px' }}>
                <table className="ui celled table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Score</th>
                            <th>Classification</th>
                            <th>Obligation</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            list && list.map((obligation, i) =>
                                <ObligationRow key={i} obligation={obligation} index={i} />
                            )
                        }
                    </tbody>
                </table>
            </div>
            
        </div>
    )
}