import React from 'react'

import FrameworkBox from './FrameworkBox'

function FrameworkList({ frameworks_list }) {

    return (

        <div className="ui grid">
            {
                frameworks_list && frameworks_list.map(framework =>
                    <FrameworkBox key={framework.id} framework={framework} />
                )
            }
        </div>
    )
}

export default FrameworkList