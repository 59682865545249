import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import { Editor } from '@tinymce/tinymce-react';
import {AgreementContext} from '../../Agreement/Agreement'
import { Dimmer, Loader, Tab } from 'semantic-ui-react'
import FrameworkMatchList from './FrameworkMatch/FrameworkMatchList'
import AnalizedSentences from './AnalizedSentences'
import TermSheet from './TermSheet'
import ObligationsAndRights from './ObligationsAndRights'
import {makeErrorToast, makeSuccessToast} from '../../makeToast'

export default function FrameworkReview({   editor_html_content, 
                                            handleSetEditorHTMLContent, 
                                            editor_text_content,
                                            handleSetEditorTextContent}) {

    const [agreement, handleSetAgreement] = useContext(AgreementContext)
    const [review_data, setReviewData] = useState()
    const [dimmer_active, setDimmerActive] = useState(false)

    useEffect( () => {
        setReviewData({})
    }, [agreement])

    const handleEditorChange = (content, editor) => {
        let text = editor.getContent({ format: 'text' })
        let html = editor.getContent({ format: 'html' })
        handleSetEditorHTMLContent(html);
        handleSetEditorTextContent(text)
    }

    let clauses_to_review = []
    let rule_matcher = []
    agreement.frameworks.map( f => {
        f.clauses.map(c => {
            clauses_to_review.push({
                clause: c.id,
                clause_name: c.verb + ' ' + c.object,
                verbs: c.verb,
                objects: c.object,

                entities: c.entities && c.entities.map(e => {
                    return ({
                        title: e.title,
                        entity_type: e.entity_type
                    })
                }),

                characteristics: c.characteristics && c.characteristics.map(e => {
                    return ({
                        title: e.title,
                        rule: e.rule
                    })
                }),
            })

            rule_matcher.push({
                clause: c.id,
                clause_name: c.verb + ' ' + c.object,
                rule: c.rule
            })
        })
    })

    const handleRunAnalyze = () => {

        setDimmerActive(true)

        var data = {
            clauses_to_review: clauses_to_review,
            content: editor_text_content,
            rule_matcher: rule_matcher
        }

        axios.post(`${window.BASE_URL}/framework/analyzeRuleMatcher`, data)
            .then(function (response) {
                makeSuccessToast('Analysis done.')
                setReviewData(response.data);
                setDimmerActive(false)
            })
            .catch(function (error) {
                makeErrorToast('Something went wrong when analysing the text.')
                console.log(error);
                setDimmerActive(false)
            });
    }


    const panes = [

        {
            menuItem: 'Document',
            render: () => (
                <Tab.Pane renderActiveOnly={false} as='div'>
                    <Editor
                        apiKey='g7mom99j970quzbu2wfz7e60ulu0a9hfl40hwsnbwb0samwy'
                        initialValue={editor_html_content}
                        init={{
                            height: 400,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            //paste_as_text: true,
                            toolbar:
                                'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                        }}
                        onEditorChange={handleEditorChange}
                    />

                    <div className="ui segment basic">
                        <div className="ui segment basic">
                            <button onClick={handleRunAnalyze} className="ui basic green button right floated">Run Complete Analysis</button>
                        </div>
                    </div>
                </Tab.Pane>
            )
        },
        {
            menuItem: 'Term Sheet',
            render: () => (
                <Tab.Pane as='div'>
                    <TermSheet review_data={review_data} />
                </Tab.Pane>
            )
        },
        ,
        {
            menuItem: 'Summary',
            render: () => (
                <Tab.Pane as='div'>
                    <ObligationsAndRights obligations_list={review_data.obligations_list} svo_summary={review_data.svo_summary} agreement_statements={review_data.agreement_statements}/>
                </Tab.Pane>
            )
        },
        {
            menuItem: 'Clause Analysis',
            render: () => (
                <Tab.Pane as='div'>
                    <FrameworkMatchList frameworks={agreement.frameworks} matched={review_data && review_data.matched} clause_rules_matched={review_data && review_data.clause_rules_matched} />
                </Tab.Pane>
            )
        },
        {
            menuItem: 'Text Analysis',
            render: () => (
                <Tab.Pane as='div'>
                    <AnalizedSentences sentences={review_data && review_data.sentences} readability_avg={review_data.readability_avg}/>
                </Tab.Pane>
            )
        }
    ]

    return (
        <div>
            <div style={{ marginBottom: '100px' }}>

                {/* <div className="ui segment basic" style={{ overflow: 'scroll', height: '800px' }}> */}
                    
                    <Dimmer.Dimmable dimmed={dimmer_active} >
                        <Dimmer active={dimmer_active} inverted>
                            <Loader>Loading</Loader>
                        </Dimmer>

                        <Tab menu={{ borderless: true, attached: false, tabular: false, style: { fontSize: '1.0rem' } }} panes={panes} />

                    </Dimmer.Dimmable>

                {/* </div> */}
            </div>
        </div>
    )
}