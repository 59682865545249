import React, { useState, useContext } from 'react'
import axios from 'axios'
import {produce} from 'immer'

export default function AddEntitie({ handleSetOnAdd, clause, handleEditClause }) {

    const [form, setForm] = useState({})

    function handleChange(e) {
        const value = e.target.value;
        setForm({
            ...form,
            [e.target.name]: value
        });
    }

    const handleCancel = () =>{
        handleSetOnAdd()
    }

    const handleSave = () => {
        var data = {
            clause_id: clause.id,
            ...form
        }

        axios.post(`${window.BASE_URL}/characteristic/add`, data)
            .then(function (response) {
                const updated_clause = produce(clause, draftClause => {
                    let new_characteristics = [...draftClause.characteristics]
                    let new_char = {
                        id: response.data.characteristic.id,
                        title: form.title,
                        rule: form.rule,
                        clause_id: clause.id
                    }

                    new_characteristics.push(new_char)

                    draftClause.characteristics = new_characteristics
                })

                handleEditClause(updated_clause)
                
                handleSetOnAdd()

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <>
            <form className='ui form' style={{ marginTop: '20px' }}>

                <div className='ui header'>Add Characteristic</div>

                <div className=" field">
                    <label>Title</label>
                    <input onChange={handleChange} value={form.title} type="text" name="title" placeholder="Title" />
                </div>
                <div className=" field">
                    <label>Rule</label>
                    <input onChange={handleChange} value={form.rule} type="text" name="rule" placeholder="Rule" />
                </div>
            </form>

            <div className='ui grid'>
                <div className='ui sixteen wide column'>
                    <div className='ui basic segment' >
                        <button onClick={handleSave} className='ui basic positive button right floated'>Add</button>
                        <button onClick={handleCancel} className='ui basic negative button right floated'>Cancel</button>
                    </div>
                </div>
            </div>
        </>
    )
}