import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import AgreementListItem from './AgreementListItem'

import { ShouldUpdateSidebarAgreementListContext} from '../../App'

export default function AgreementList() {

    const [agreement_name, setAgreementName] = useState('')

    const [agreement_list, SetAgreementList] = useState([])

    const [should_update_agreement_list, handleSetShouldUpdateAgreementList] = useContext(ShouldUpdateSidebarAgreementListContext)

    const handleDeletedAgreement = (id) => {

        let updated_agreements = agreement_list.filter(function (a, i) {
            return a.pk !== id
        })
        handleSetShouldUpdateAgreementList()
        SetAgreementList(updated_agreements)
    }

    useEffect(() => {
        axios.get(`${window.BASE_URL}/agreement/get_agreement_list`)
            .then(function (response) {
                SetAgreementList(response.data.agreement_list)
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    }, [])

    const handleAgreementName = (e) => {
        setAgreementName(e.target.value)
    }

    const handleAddAgreement = () => {
        var qs = require('qs');

        var data = qs.stringify({
            title: agreement_name
        });

        const config = {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
        };

        axios.post(`${window.BASE_URL}/agreement/new`, data, config)
            .then(function (response) {
                let new_agreements = [...agreement_list, response.data.agreement]
                SetAgreementList(new_agreements)
                handleSetShouldUpdateAgreementList()
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div id="main-content" style={{ marginTop: '120px' }}>
            <div className="ui container">

                <h2 className='ui header'>Agreement List</h2>

                <div className='ui grid'>
                    <div className='ui six wide column'>
                        <div className="ui fluid action input">
                            <input value={agreement_name} onChange={handleAgreementName} type="text" placeholder="Agreement name" />
                            <div onClick={handleAddAgreement} className="ui basic green button">Add</div>
                        </div>
                    </div>
                </div>

                <div className='ui grid'>
                    <div className='ui ten wide column'>
                        <div className="ui middle aligned divided list">
                            {
                                agreement_list && agreement_list.map(a =>
                                    <AgreementListItem key={a.pk} agreement={a} handleDeletedAgreement={handleDeletedAgreement}/>
                                )
                            }
                        </div>
                        
                    </div>
                </div>
                
            </div>
        </div>
    )
}