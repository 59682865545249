import React, { useContext, useEffect, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import axios from 'axios'
import {AgreementContext} from '../../Agreement/Agreement'
import { Dropdown, Menu, Icon } from 'semantic-ui-react'
import { makeErrorToast } from '../../makeToast'

export default function ClauseAssembly({ checked_clauses }) {

    const [agreement, handleSetAgreement] = useContext(AgreementContext)

    const [input_variables, setInputVariables] = useState([])

    const [assembled, setAssembled] = useState('')

    //build the template contract from the clauses of the agreement
    const html_asembly = ReactDOMServer.renderToString(<div className='ui grid'>
        <div className='ui sixteen wide column'>
            <ol>
                {
                    agreement.frameworks && agreement.frameworks.map(f =>
                        f.clauses && f.clauses.map(c =>
                            checked_clauses && checked_clauses.includes(c.id)
                                ?
                                c.sentences && c.sentences.map(s => {
                                    if(s.default){
                                        return <li key={s.id}>{c.caption}. {s.content}</li>
                                    }
                                })
                                : <></>
                        )
                    )
                }
            </ol>
        </div>
    </div>)

    useEffect(() => {

        let data = {
            assembly: html_asembly,
            variables: []
        }

        axios.post(`${window.BASE_URL}/framework/parse_assembly`, data)

            .then(response => {
                setAssembled(response.data.assembled)
                setInputVariables(response.data.variables)
            })
            .catch(function (error) {
                makeErrorToast(`Something went wrong when parsing the document. ${error.response.data.message}`)
            });

    }, [checked_clauses])

    const handleChange = (e) => {
        let new_variables = [...input_variables]

        new_variables.map(v => {
            if (v.key === e.target.name) {
                v.value = e.target.value
            }
        })

        setInputVariables(new_variables)
    }

    const handleApply = () => {
        let data = {
            assembly: html_asembly,
            variables: input_variables
        }

        axios.post(`${window.BASE_URL}/framework/parse_assembly`, data)

            .then(response => {
                setAssembled(response.data.assembled)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div>

            <Menu attached='top'>
                <Dropdown item icon='wrench' simple>
                    <Dropdown.Menu>
                        <div className='ui segment' style={{width: '800px'}}>
                            <form className="ui form">
                                <div className='three fields'>
                                    {
                                        input_variables && input_variables.map(v => (
                                            <div key={v.key} className=" field">
                                                <label>{v.key}</label>
                                                <input onChange={handleChange} value={v.value} type="text" name={v.key} placeholder="" />
                                            </div>
                                        ))
                                    }
                                </div>
                            </form>
                            <button className='ui basic button' onClick={handleApply}>Apply</button>
                        </div> 
                    </Dropdown.Menu>
                </Dropdown>
            </Menu>
            <div className='ui segment attached bottom' dangerouslySetInnerHTML={{ __html: assembled }}>

            </div>
        </div>

    )
}