import React from 'react'
import { sortableHandle } from 'react-sortable-hoc';

const DragHandle = sortableHandle(() => <div className="ui right floated"><i className="bars icon"></i></div>);

export default function SeparatorItem({ separator, onClickHandle }) {

    return (
        <div className="item" onClick={onClickHandle}>
            <div className="content">
                {separator.title}
                <DragHandle />
            </div>
        </div>

    )
}