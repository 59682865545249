import React, { useState } from 'react'
import GaugeChart from 'react-gauge-chart'
import { Label } from 'semantic-ui-react'

export default function ReadabilityGaugeChart({ raw_readability, highest_raw_readability, highest_raw_readability_text, sentences_count, word_count, calculated_cost }) {

    const readability_value_chart = raw_readability <= 30 ? (raw_readability / 30) : 1
    const handleHighestClick = () => {
        setTimeout(function () {
            alert(highest_raw_readability_text)
        }, 100);
    }

    return (
        <div className="ui segment">

            <div className='ui basic segment'>
                <h3 className='ui header'>Readability Score</h3>
            </div>
            <GaugeChart id="gauge-chart2"
                nrOfLevels={30}
                percent={readability_value_chart}
                formatTextValue={value => ''}
                hideText={false}
                textColor='#0'
            />

            <div className='ui basic segment'>
                <table className='ui celled table'>
                    <thead>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Grade Level</td>
                            <td>{raw_readability}</td>
                        </tr>
                        <tr>
                            <td><Label as='a' onClick={handleHighestClick}>Highest Grade Level</Label></td>
                            <td>{highest_raw_readability}</td>
                        </tr>
                        <tr>
                            <td>Number of Sentences</td>
                            <td>{sentences_count}</td>
                        </tr>
                        <tr>
                            <td>Word Count</td>
                            <td>{word_count}</td>
                        </tr>
                        <tr>
                            <td>Calculated Cost</td>
                            <td>${calculated_cost.estimated_cost}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}