import React, { useState, useContext } from 'react'
import axios from 'axios'
import produce from 'immer'

export default function EditCharacteristic({ clause, characteristic, handleSetOnEdit, handleEditClause }) {

    const [form, setForm] = useState(characteristic)

    function handleChange(e) {
        const value = e.target.value;
        setForm({
            ...form,
            [e.target.name]: value
        });
    }

    const handleSave = () => {

        var data = {
            ...form
        }

        axios.post(`${window.BASE_URL}/characteristic/edit`, data)
            .then(function (response) {

                const updated_clause = produce(clause, draftClause => {
                    draftClause.characteristics.map(c => {
                        if (c.id === form.id) {
                            c.title = form.title
                            c.rule = form.rule
                        }
                    })
                })

                handleEditClause(updated_clause)

                handleSetOnEdit()
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div style={{ marginBottom: '20px', marginTop: '20px' }}>

            <div className='ui header'>Edit Characteristic</div>

            <form className='ui form'>
                <div className=" field">
                    <label>Title</label>
                    <input onChange={handleChange} value={form.title} type="text" name="title" placeholder="Title" />
                </div>
                <div className=" field">
                    <label>Content</label>
                    <input onChange={handleChange} value={form.rule} type="text" name="rule" placeholder="rule" />
                </div>
            </form>
            <div className='ui grid'>
                <div className='ui sixteen wide column'>
                    <div className='ui basic segment'>
                        <button className='ui basic positive button right floated' onClick={handleSave}>Save</button>
                        <button className='ui basic negative button right floated' onClick={handleSetOnEdit}>Cancel</button>
                    </div>
                </div>

            </div>
        </div >
    )
}