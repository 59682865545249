import React, { useState, useContext } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import axios from 'axios'

import { AgreementContext } from '../../Agreement/Agreement'

export default function EditFramework({ framework }) {

    const [isOpen, setIsOpen] = useState(false)
    const [current_framework_title , setCurrentFrameworkTitle] = useState(framework.title)
    const [current_framework_caption , setCurrentFrameworkCaption] = useState(framework.caption)

    const [agreement, handleSetAgreement] = useContext(AgreementContext)

    const handleClose = () => {
        setIsOpen(!isOpen)
    }

    const handleDelete = () => {

        var data = {
            frameworkId: framework.id,
            agreementId: agreement.agreement_id
        }

        axios.post(`${window.BASE_URL}/framework/deleteFramework`, data)
            .then(function (response) {
                
                setIsOpen(false)

                handleSetAgreement(agreement => {
                    agreement.frameworks = agreement.frameworks.filter(f => f.id !== framework.id)
                })

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleSave = () => {

        var data = {
            frameworkId: framework.id,
            title: current_framework_title,
            caption: current_framework_caption,
            agreementId: agreement.agreement_id
        }

        axios.post(`${window.BASE_URL}/framework/editFramework`, data)
            .then(function (response) {
                
                handleSetAgreement(agreement => {
                    agreement.frameworks.map(f => {
                        if (f.id === framework.id) {
                            f.title = data.title
                            f.caption = data.caption
                        }
                    })
                })

                setIsOpen(false)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleAddSeparator = () => {

        var data = {
            frameworkId: framework.id,
            agreementId: agreement.agreement_id
        }

        axios.post(`${window.BASE_URL}/clauseseparators/add_separator`, data)
            .then(function (response) {

                setIsOpen(false)

                handleSetAgreement(agreement => {
                    agreement.frameworks.map(function (f, i) {
                        if (f.id === framework.id) {
                            let separators = f.separators 
                            separators.push({ 
                                id: response.data.id,
                                title: response.data.title,
                                sort_order: response.data.sort_order,
                                framework_id: response.data.framework_id,
                                is_separator: response.data.is_separator
                            })
                            f.separators = separators
                        }
                    })
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <Modal onClose={handleClose} open={isOpen} trigger={
                <a onClick={handleClose} data-framework-id={framework.id} className="ui right corner label framework-settings">
                    <i className="cog icon"></i>
                </a>
            }>
            <Modal.Header>Edit Framework</Modal.Header>
            <Modal.Content>
                <div className="ui segment very basic padded">
                    <form className="ui form">
                        <div className=" field">
                            <label>Title</label>
                            <input onChange={e => setCurrentFrameworkTitle(e.target.value)} value={current_framework_title} type="text" name="title" placeholder="Title" />
                        </div>
                        <div className=" field">
                            <label>Caption</label>
                            <input onChange={e => setCurrentFrameworkCaption(e.target.value)} value={current_framework_caption} type="text" name="caption" placeholder="Caption" />
                        </div>
                        <input type="hidden" name="agreementId" value="{{ agreement_id }}" />
                    </form>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <div className="ui grid">
                    <div className="ui two wide column">
                        <div onClick={handleDelete} className="ui negative labeled icon button">
                            Delete<i className="trash icon"></i>
                        </div>
                    </div>
                    <div className="ui seven wide column right floated">
                        <Button onClick={handleAddSeparator}>Add separator</Button>
                        <Button onClick={handleClose} negative>Cancel</Button>
                        <Button
                            onClick={handleSave}
                            positive
                            labelPosition='right'
                            icon='checkmark'
                            content='Save'
                        />
                    </div>
                </div>
            </Modal.Actions>
        </Modal>
    )
}
