import React from 'react'
import kr_logo from '../../kr_logo.png'
import wcc from '../../wcc.jpg'

export default function AnalisysLogos(){
    return(
        <div className='ui grid'>
            <div className='ui twelve wide column'>
                <img src={kr_logo} width='auto' height='50px' />
            </div>
            <div className='ui four wide column'>
                <img src={wcc} width='auto' height='50px' />
            </div>
        </div>
    )
}